import { normalize, schema } from 'normalizr';
import { v4 as uuidv4 } from 'uuid';
import { ToastTypes } from '../../../enums/ToastTypes';
import { ValueType } from '../../../model/cohorted-test.model';
import { getData, postData } from '../../../network/request';
import { showToast } from '../../../pages/CohortedTestsPage/cohortedTestsPageSlice';
import {
  fetchVariantsError,
  fetchVariantsPending,
  fetchVariantsSuccess,
  saveVariantsError,
  saveVariantsPending,
  saveVariantsSuccess,
} from './featureSlice';

export const getVariantInfo = (testId: string, featureCode: string) => {
  return (dispatch: any) => {
    dispatch(fetchVariantsPending());
    getData(`optimus/cohorted_tests/${testId}/features`, {
      object_type: 'game',
      feature_code: featureCode,
    })
      .then(res => {
        if (res.error) {
          throw res.error;
        }

        const normalizedVariants: { [id: string]: any } = {};

        for (const key in res.variants) {
          if (!res.variants.hasOwnProperty(key)) {
            return;
          }

          const variantContent = res.variants[key][0];

          const variantWithIds = addIdsToSettings(variantContent);
          normalizedVariants[key] = normaliseSettings(variantWithIds);
        }

        normalizedVariants['control'] = normaliseSettings(
          addIdsToSettings(res.original[0]),
        );

        dispatch(fetchVariantsSuccess(normalizedVariants));

        return res.cohorted_tests;
      })
      .catch(error => {
        dispatch(fetchVariantsError(error));
      });
  };
};

export function addIdsToSettings(settings: any, parentType?: ValueType) {
  let newSettings = { ...settings };

  if (parentType === ValueType.ARRAY) {
    newSettings = [...settings];
  }

  // Add id
  for (const key in newSettings) {
    if (!newSettings.hasOwnProperty(key)) {
      return;
    }

    let value = newSettings[key];
    const valueWithId: {
      id: string;
      name: string;
      type: string;
      value: any;
    } = {
      id: uuidv4(),
      name: key,
      type: Array.isArray(value)
        ? ValueType.ARRAY
        : Number.isInteger(value)
        ? ValueType.INT
        : typeof value === 'number' && !Number.isInteger(value)
        ? ValueType.DOUBLE
        : value === null
        ? ValueType.NULL
        : typeof value,
      value,
    };

    if (typeof value === 'object' || Array.isArray(value)) {
      value = addIdsToSettings(newSettings[key]);
    } else if (value.__type__ === ValueType.ARRAY) {
      value = addIdsToSettings(newSettings[key].__value__, value.__type__);
    }

    valueWithId.value = value;

    newSettings[key] = valueWithId;
  }

  return newSettings;
}

export function normaliseSettings(settingsWithIds: any) {
  const settingSchema = new schema.Entity('settings');

  const settingsListSchema = new schema.Array(settingSchema);

  settingSchema.define({ value: settingsListSchema });

  return normalize(settingsWithIds.settings, settingSchema);
}

export function denormalizeSettings(
  settings: any,
  idArray: string[],
  isParentArray?: boolean,
): any {
  const denormalizedSettings: { [id: string]: any } = {};
  //Loop through top level object

  for (let i = 0; i < idArray.length; i++) {
    const settingValue = settings[idArray[i]];

    switch (settingValue.type) {
      case ValueType.ARRAY:
        if (isParentArray) {
          const array: any[] = [];
          settingValue.value.forEach((id: string) => {
            array.push(denormalizeSettings(settings, [id], true));
          });

          return array;
        }
        denormalizedSettings[settingValue.name] = [];
        settingValue.value.forEach((id: string) => {
          denormalizedSettings[settingValue.name].push(
            denormalizeSettings(settings, [id], true),
          );
        });

        break;
      case ValueType.OBJECT:
        if (isParentArray) {
          return denormalizeSettings(settings, settingValue.value, false);
        }
        denormalizedSettings[settingValue.name] = denormalizeSettings(
          settings,
          settingValue.value,
          false,
        );
        break;
      case ValueType.INT:
        if (isParentArray) {
          return settingValue.value;
        }
        denormalizedSettings[settingValue.name] = settingValue.value;
        break;
      default:
        if (isParentArray) {
          return settingValue.value;
        }
        denormalizedSettings[settingValue.name] = settingValue.value;
        break;
    }
  }

  return denormalizedSettings;
}

export const saveVariants = (
  testId: string,
  featureCode: string,
  variants: any,
) => {
  return (dispatch: any) => {
    dispatch(saveVariantsPending());

    const variantsClone = { ...variants };
    delete variantsClone.control;

    const denormalizedVariants: { [id: string]: any } = {};

    // Denormalize variants
    Object.keys(variantsClone).forEach((key: any) => {
      const variant = variantsClone[key];

      denormalizedVariants[key] = [
        denormalizeSettings(variant.entities.settings, [variant.result], false),
      ];
    });

    const data = {
      object_type: 'game',
      feature_code: featureCode,
      variants: denormalizedVariants,
    };

    // Post to features endpoint
    postData(`/optimus/cohorted_tests/${testId}/features`, data)
      .then(res => {
        if (res.error) {
          throw res.error;
        }
        dispatch(saveVariantsSuccess());
        dispatch(
          showToast({
            type: ToastTypes.SUCCESS,
            message: 'Variants saved successfully',
          }),
        );

        return res.cohorted_tests;
      })
      .catch(error => {
        dispatch(saveVariantsError(error));
        dispatch(
          showToast({
            type: ToastTypes.ERROR,
            message: 'Failed to save variants',
          }),
        );
      });
  };
};
