import {
  faExclamationTriangle,
  faPause,
  faSearch,
  faStop,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Badge,
  Button,
  ButtonGroup,
  Col,
  Form,
  Input,
  Popover,
  PopoverBody,
  Row,
  Spinner,
  UncontrolledAlert,
  UncontrolledTooltip,
} from 'reactstrap';
import { Action, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { toast } from 'react-toastify';
import moment from 'moment';
import { CohortedTest, GlobalState } from '../../model/cohorted-test.model';
import {
  changeTestContents,
  getAddFeaturePending,
  getCohortedTests,
  getCohortedTestsError,
  getCohortedTestsPending,
  getGameEditionsMap,
  getGameSettings,
  getLoadingGameEditions,
  getLoadingGameSettings,
  getNewVariantCodes,
  getSelectedTestId,
} from '../../pages/CohortedTestsPage/cohortedTestsPageSlice';
import isNullOrUndefined from '../../util/isNullOrUndefined';
import { ConfirmationModal } from '../ConfirmationModal/ConfirmationModal';
import FeaturesModal from '../FeaturesModal/FeaturesModal';
import Field from '../Field/Field';
import InputField from '../InputField/InputField';
import SelectField, { fallbackGameEditions } from '../SelectField/SelectField';
import {
  executeAction,
  updateSelectedTest,
} from '../../pages/CohortedTestsPage/operations';
import Toast from '../Misc/Toast';
import Feature from './Feature/Feature';

export enum TestState {
  DISABLED = 'disabled',
  ACTIVE = 'active',
  ENDED = 'paused',
  RESOLVED = 'complete',
  PENDING_DELETE = 'pending_delete',
  PENDING_ACTIVE = 'pending_active',
  PARTIAL_PAUSE = 'partial_pause',
  PENDING_COMPLETE = 'pending_complete',
}

export const testStateMap: { [id: string]: string } = {
  disabled: 'Disabled',
  active: 'Active',
  paused: 'Ended',
  complete: 'Resolved',
  pending_active: 'Pending Activation',
  pending_delete: 'Pending Deletion',
  pending_complete: 'Pending Resolved',
  partial_pause: 'Partially Ended',
};

type Validator = <K extends keyof CohortedTest>(
  key: K,
  value: CohortedTest[K],
) => boolean;

export interface Props {
  cohortedTest: CohortedTest;
  testState: TestState;
  onValidate?: Validator;
  updateSelectedTest: (test: CohortedTest) => void;
  executeAction: (
    testId: string,
    data: Partial<{
      country_codes: string[];
      status: string;
      winning_group_id: string;
      generate_groups: boolean;
      kill_group: boolean;
      group_id: string;
    }>,
    successMessage?: string,
    errorMessage?: string,
  ) => void;
  addFeaturePending: boolean;
  countries?: { [id: string]: string };
  changeTestContents: (test: CohortedTest) => void;
  newVariantCodes: { [id: string]: { feature: string; variant: string }[] };
  selectedTestId: string;
  gameEditionsMap?: any;
  loadingGameEditions?: boolean;
  loadingGameSettings?: boolean;
  gameSettings?: any[];
}

export interface PopoverState {
  target: string;
  hoverText: string;
}

export interface State {
  popover?: PopoverState;
  isAcquisitionLimit: boolean;
  openFeature?: string;
  searchString: string;
  newFeatureName?: string;
  currentAction?: string;
  currentActionPrompt?: string;
  currentActionAction?: string;
  currentActionTitle?: string;
  countryCodesToUpdate: string[];
  singleCountryToUpdate?: string[];
  statusPerCountryModal?: string;
  statusPerCountryModalAction?: string;
  statusPerCountryModalPrompt?: string;
  statusPerCountryModalTitle?: string;
  statusPerCountryStatus?: string;
  statusPerCountrySearchString?: string;
  groupIdToKill?: string;
  ActionGroupId?: string;
}

class CohortedTestForm extends Component<Props, State> {
  state: State = {
    isAcquisitionLimit: !!this.props.cohortedTest.acquisition_limit,
    searchString: '',
    countryCodesToUpdate: [],
  };

  handleChange(key: string, value: any) {
    let testClone = Object.assign(this.props.cohortedTest);

    testClone = { ...testClone, [key]: value };

    // update test in redux store
    this.props.changeTestContents(testClone);
  }

  updateState(stateName: string, value: any) {
    //@ts-ignore
    this.setState({ [stateName]: value });
  }

  setCurrentFeature(featureId: string) {
    this.setState({ openFeature: featureId });
  }

  setPopover(state?: PopoverState) {
    this.setState({ popover: state });
  }

  addFeature() {
    if (!this.state.newFeatureName) {
      toast.error(
        <Toast icon={faExclamationTriangle}>
          Feature name cannot be empty
        </Toast>,
        {
          toastId: 'feature_name_empty',
        },
      );

      return;
    }

    // Generate new feature code
    // Extract numbers from codes
    const featureCodeNumbers = this.props.cohortedTest.features!.map(feature =>
      parseInt(feature.code.replace('f', '')),
    );

    if (featureCodeNumbers.length === 0) {
      featureCodeNumbers.push(0);
    }

    // Get highest number
    const newFeatureCode = `f${Math.max(...featureCodeNumbers) + 1}`;

    const features = [...(this.props.cohortedTest.features ?? [])];

    features.push({
      code: newFeatureCode,
      name: this.state.newFeatureName,
      variants: [],
    });

    const test = { ...this.props.cohortedTest, features };

    this.props.changeTestContents(test);

    this.setState({ newFeatureName: '' });
  }

  deleteFeature(featureId: string) {
    const features = [...this.props.cohortedTest.features!];

    const index = features.findIndex(feature => feature.code === featureId);

    features.splice(index, 1);

    const test = { ...this.props.cohortedTest, features };

    this.props.changeTestContents(test);
  }

  executeAction(action?: string) {
    switch (action) {
      case 'generateGroups':
        this.props.executeAction(
          this.props.cohortedTest.object_id!,
          {
            generate_groups: true,
          },
          'Generated groups successfully',
          'Failed to generate groups',
        );
        break;
      case 'changeTestStatus':
        this.props.executeAction(
          this.props.cohortedTest.object_id!,
          {
            status:
              this.props.testState === TestState.DISABLED
                ? TestState.ACTIVE
                : this.props.testState === TestState.ACTIVE
                ? TestState.ENDED
                : TestState.RESOLVED,
            country_codes: this.props.cohortedTest.country_codes,
          },
          'Test activated successfully',
          'Failed to activate test',
        );
        break;
      case 'activateForCountry':
        this.props.executeAction(
          this.props.cohortedTest.object_id!,
          {
            status: 'active',
            country_codes: this.state.singleCountryToUpdate,
          },
          `Test activated for country successfully`,
          'Failed to activate test for country',
        );
        break;
      case 'pauseForCountry':
        this.props.executeAction(
          this.props.cohortedTest.object_id!,
          {
            status: 'paused',
            country_codes: this.state.singleCountryToUpdate,
          },
          `Test ended for country successfully`,
          'Failed to end test for country',
        );
        break;
      case 'completeForCountry':
        this.props.executeAction(
          this.props.cohortedTest.object_id!,
          {
            status: 'complete',
            country_codes: this.state.singleCountryToUpdate,
            group_id: this.state.ActionGroupId,
          },
          `Test resolved for country successfully`,
          'Failed to resolve test for country',
        );
        break;
      case 'activateForCountries':
        this.props.executeAction(
          this.props.cohortedTest.object_id!,
          {
            status: 'active',
            country_codes: this.state.countryCodesToUpdate,
          },
          `Test activated for countries successfully`,
          'Failed to activate test for countries',
        );
        break;
      case 'pauseForCountries':
        this.props.executeAction(
          this.props.cohortedTest.object_id!,
          {
            status: 'paused',
            country_codes: this.state.countryCodesToUpdate,
          },
          `Test ended for countries successfully`,
          'Failed to ended test for countries',
        );
        break;
      case 'completeForCountries':
        this.props.executeAction(
          this.props.cohortedTest.object_id!,
          {
            status: 'complete',
            country_codes: this.state.countryCodesToUpdate,
            group_id: this.state.ActionGroupId,
          },
          `Test resolved for countries successfully`,
          'Failed to resolved test for countries',
        );
        break;
      case 'killGroup':
        this.props.executeAction(
          this.props.cohortedTest.object_id!,
          {
            kill_group: true,
            group_id: this.state.groupIdToKill,
          },
          `Group ID ${this.state.groupIdToKill} killed successfully`,
          'Failed to kill group',
        );
        break;
      default:
        break;
    }

    this.setState({ countryCodesToUpdate: [] });
  }

  render() {
    return (
      <>
        <Form>
          {this.renderHeading()}
          {this.renderTopFields()}
          <Row form>
            {this.renderLeftColumn()}
            {this.renderRightColumn()}
          </Row>
        </Form>
        {this.renderPopover()}
        {this.renderConfirmationModal()}
        {this.renderStatusPerCountryModal()}
      </>
    );
  }

  private renderHeading() {
    return (
      <>
        <div className="d-flex justify-content-between align-items-center">
          <Input
            className="test-title font-weight-light mt-0 mb-2 mr-2 p-2"
            plaintext
            value={this.props.cohortedTest.name}
            onChange={event => this.handleChange('name', event.target.value)}
            disabled={this.props.testState !== TestState.DISABLED}
          />
          {this.props.testState !== TestState.PENDING_DELETE && (
            <Button
              className="primary-btn"
              onClick={() =>
                this.props.updateSelectedTest(this.props.cohortedTest)
              }
            >
              Save
            </Button>
          )}
        </div>
        <small className="text-muted">
          Last updated:{' '}
          {this.props.cohortedTest.updated_on &&
            moment(this.props.cohortedTest.updated_on).format(
              'MMMM Do YYYY, HH:mm',
            )}
        </small>
        <hr />
      </>
    );
  }

  private renderTopFields() {
    const { testState, cohortedTest } = this.props;

    return (
      <>
        <Field
          id="description"
          title="Description"
          hoverText="Use this text box to enter a description for your new cohorted test."
          setPopover={this.setPopover.bind(this)}
          disabled={testState !== TestState.DISABLED}
        >
          <InputField
            type="textarea"
            value={cohortedTest.description}
            handleChange={this.handleChange.bind(this)}
          />
        </Field>
        <Field
          id="features"
          title="Features"
          hoverText="Use this field to edit features and variants for your new cohorted test."
          setPopover={this.setPopover.bind(this)}
          disabled={this.props.addFeaturePending}
        >
          {this.renderFeatures()}
        </Field>
      </>
    );
  }

  private renderLeftColumn() {
    const { testState } = this.props;

    return (
      <Col md={6}>
        <Field
          id="country_codes"
          title="Countries"
          hoverText="Specifies the countries included in the test"
          setPopover={this.setPopover.bind(this)}
          disabled={testState !== TestState.DISABLED}
        >
          {this.renderCountryCodes()}
        </Field>
        <Field
          id="status_per_country"
          title="Status Per Country"
          hoverText="Use this field to control the test status per country"
          setPopover={this.setPopover.bind(this)}
        >
          {this.renderStatusPerCountry()}
        </Field>
      </Col>
    );
  }

  getCountryOptions() {
    const countryOptions: { [id: string]: string } = {};

    const actionToStatusMap: { [id: string]: string } = {
      activateForCountries: 'active',
      pauseForCountries: 'paused',
      completeForCountries: 'complete',
    };

    Object.keys(this.props.countries!).forEach(key => {
      // Get status for country
      const statusesToHide = [];
      const status = this.props.cohortedTest.status_per_country?.find(
        statusObj => statusObj.country_code === key,
      )?.status;

      //Filter country options based on status
      if (this.state.currentAction === 'completeForCountries') {
        statusesToHide.push('acquiring');
        statusesToHide.push('active');
      }

      if (
        status === actionToStatusMap[this.state.currentAction!] ||
        (status === 'complete' &&
          this.state.currentAction === 'pauseForCountries')
      ) {
        statusesToHide.push(status);
      }

      if (
        this.props.cohortedTest.country_codes?.includes(key) &&
        !statusesToHide.includes(
          actionToStatusMap[this.state.currentAction!],
        ) &&
        !statusesToHide.includes(status)
      ) {
        countryOptions[key] = this.props.countries![key];
      }
    });

    return Object.keys(countryOptions);
  }

  private renderRightColumn() {
    const { testState, cohortedTest } = this.props;

    // Pull out activity values
    const activityValues: { [id: string]: number | null | undefined } = {
      Created: this.props.cohortedTest.created_on,
      Activated: this.props.cohortedTest.activated_on,
      Paused: this.props.cohortedTest.paused_on,
      Deleted: this.props.cohortedTest.deleted_on,
      Completed: this.props.cohortedTest.completed_on,
    };

    const isDisabled = testState === TestState.DISABLED;

    const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');

    return (
      <Col md={6}>
        <Field
          id="game_edition_id"
          title="Game Edition"
          hoverText="This field applies edition filters (ios/ android etc) to the cohorted test."
          setPopover={this.setPopover.bind(this)}
          disabled
        >
          <SelectField
            value={cohortedTest.game_edition_id}
            map={this.props.gameEditionsMap ?? fallbackGameEditions}
            handleChange={this.handleChange.bind(this)}
          />
        </Field>
        <Field
          id="created_on"
          title="Activity"
          hoverText="This block shows some basic activity on this test such as activation/resolution dates."
          setPopover={this.setPopover.bind(this)}
        >
          {Object.keys(activityValues)
            .filter(key => !isNullOrUndefined(activityValues[key]))
            .sort((a, b) => activityValues[a]! - activityValues[b]!)
            .map(key => {
              const value = moment(activityValues[key]!).format(
                'MMMM Do YYYY, HH:mm',
              );

              return (
                <React.Fragment key={key}>
                  <span>
                    {key} on: {value}
                  </span>{' '}
                  <br />
                </React.Fragment>
              );
            })}
        </Field>
        <Field
          id="status"
          title="Status"
          hoverText="This is the status of your test, it will update itself with information or controls depending on the context of the test's current status."
          setPopover={this.setPopover.bind(this)}
        >
          <div className="d-flex justify-content-between align-items-center">
            <span>Test is {testStateMap[cohortedTest.status]}</span>
            <ButtonGroup>
              <Button
                size="sm"
                color="primary"
                disabled={
                  testState === TestState.ACTIVE ||
                  testState === TestState.RESOLVED ||
                  !isDisabled ||
                  cohortedTest.groups?.length === 0
                }
                onClick={() => {
                  this.setState(
                    {
                      currentAction: 'activateForCountries',
                      currentActionAction: 'Activate',
                      currentActionPrompt: 'This action is irreversible.',
                      currentActionTitle: 'Activate Test?',
                    },
                    () =>
                      this.setState({
                        countryCodesToUpdate: this.getCountryOptions(),
                      }),
                  );
                }}
              >
                Activate Test
              </Button>
              <Button
                disabled={
                  testState === TestState.DISABLED ||
                  testState === TestState.RESOLVED ||
                  testState === TestState.ENDED
                }
                size="sm"
                onClick={() => {
                  this.setState(
                    {
                      currentAction: 'pauseForCountries',
                      currentActionPrompt: 'End test?',
                      currentActionTitle: 'End test?',
                      currentActionAction: 'End',
                    },
                    () =>
                      this.setState({
                        countryCodesToUpdate: this.getCountryOptions(),
                      }),
                  );
                }}
              >
                End Test
              </Button>
              <Button
                disabled={testState !== TestState.ENDED}
                size="sm"
                onClick={() => {
                  this.setState(
                    {
                      currentAction: 'completeForCountries',
                      currentActionPrompt: 'Resolve test?',
                      currentActionTitle: 'Resolve test?',
                      currentActionAction: 'Resolve',
                    },
                    () =>
                      this.setState({
                        countryCodesToUpdate: this.getCountryOptions(),
                      }),
                  );
                }}
              >
                Resolve Test
              </Button>
            </ButtonGroup>
          </div>

          <small className="text-muted">
            {cohortedTest.groups?.length === 0 &&
              'You must generate groups before activating this test'}
          </small>
        </Field>
        <Field
          id="groups"
          title="Groups"
          hoverText="You can generate these groups automatically from the Features and Variants you have created."
          setPopover={this.setPopover.bind(this)}
        >
          {this.renderGroupErrors()}

          <div className="d-flex justify-content-between align-items-center">
            <span>{cohortedTest.groups?.length} groups generated</span>
            <Button
              disabled={testState !== TestState.DISABLED}
              className="field-content align-self-start"
              size="sm"
              onClick={() =>
                this.setState({
                  currentAction: 'generateGroups',
                  currentActionPrompt:
                    'This will remove any existing groups and generate new ones based on your features and variants.\n' +
                    'Any previous groups will be lost.',
                  currentActionTitle: 'Generate Groups?',
                  currentActionAction: 'Generate',
                })
              }
            >
              Generate Groups
            </Button>
          </div>

          <hr />
          <div className="groups-container">
            {this.props.cohortedTest.groups?.map(group => (
              <div
                className={classNames('group', {
                  dead: group.user_share === 0,
                })}
                key={group.group_id}
              >
                <div className="d-flex align-items-center">
                  <p className="group-id">
                    Group {alphabet[parseInt(group.group_id)]?.toUpperCase()}{' '}
                    {group.user_share === 0 && <Badge size="small">Dead</Badge>}
                  </p>
                  {group.user_share !== 0 && (
                    <Button
                      disabled={group.user_share === 0}
                      size="sm ml-2"
                      onClick={event => {
                        event.stopPropagation();
                        this.setState({
                          currentAction: 'killGroup',
                          currentActionPrompt:
                            'Are you sure you want to kill Group ID: ' +
                            group.group_id +
                            '?',
                          currentActionTitle: 'Kill Group?',
                          currentActionAction: 'Kill',
                          groupIdToKill: group.group_id,
                        });
                      }}
                    >
                      Kill Group
                    </Button>
                  )}
                </div>

                <small className="text-muted">
                  User share: {group.user_share}
                </small>
                {group.segment_id && (
                  <>
                    <br />
                    <small className="text-muted">
                      Segment ID: {group.segment_id}
                    </small>
                  </>
                )}
                <p className="desc">{group.description}</p>
              </div>
            ))}
          </div>
        </Field>
        <Field
          id="client_id"
          title="Client ID"
          hoverText="This text field dictates whether the client is purposefully forcing the test group of the game."
          setPopover={this.setPopover.bind(this)}
          disabled={testState !== TestState.DISABLED}
        >
          <InputField
            type="text"
            value={cohortedTest.client_id!}
            handleChange={this.handleChange.bind(this)}
          />
        </Field>
        <Field
          id="target_day"
          title="Target Day"
          hoverText="The day to target for tests."
          setPopover={this.setPopover.bind(this)}
          disabled={testState !== TestState.DISABLED}
        >
          <InputField
            type="number"
            value={cohortedTest.target_day}
            handleChange={this.handleChange.bind(this)}
          />
        </Field>
      </Col>
    );
  }

  private renderGroupErrors() {
    const groups = this.props.cohortedTest.groups;
    const features = this.props.cohortedTest.features;

    let variantWithoutEffects = false;

    // Calculate group amount
    let expectedGroupAmount = 0;
    features?.forEach(feature => {
      if (expectedGroupAmount === 0) {
        expectedGroupAmount = 1;
      }
      expectedGroupAmount *= (feature.variants?.length ?? 0) + 1;
      feature.variants?.forEach((variant: { effects: string | any[] }) => {
        variantWithoutEffects = variant?.effects?.length === 0;
      });
    });

    // Check for group without effects
    let groupWithoutEffects = false;
    groups?.forEach((group, index) => {
      if (group.effect_ids.length === 0 && index !== 0) {
        groupWithoutEffects = true;
      }
    });

    return (
      <>
        {expectedGroupAmount !== groups?.length && (
          <UncontrolledAlert color="warning">
            The number of groups is incorrect based on the number of features
            and variants. <br />
            <strong>
              {`${groups?.length !== 0 ? 'Reg' : 'G'}enerate`} groups below
            </strong>
          </UncontrolledAlert>
        )}
        {groupWithoutEffects && (
          <UncontrolledAlert color="warning">
            A group exists with no effects. <br />
            <strong>Check your effects then regenerate groups below</strong>
          </UncontrolledAlert>
        )}
        {variantWithoutEffects && (
          <UncontrolledAlert color="warning">
            A variant exists with no effects. <br />
            <strong>Check your effects then regenerate groups below</strong>
          </UncontrolledAlert>
        )}
      </>
    );
  }

  private renderPopover() {
    return (
      this.state.popover && (
        <Popover
          isOpen={!!this.state.popover}
          target={this.state.popover.target}
        >
          <PopoverBody>{this.state.popover.hoverText}</PopoverBody>
        </Popover>
      )
    );
  }

  private renderFeatures() {
    if (!this.props.cohortedTest.features) {
      return;
    }

    return (
      <div className="d-flex flex-column">
        <div className="search d-flex justify-content-between align-items-center my-2 pb-2 text-muted">
          <small>
            <FontAwesomeIcon icon={faSearch} className="mr-2" />
          </small>
          <Input
            plaintext
            className="small outline-none p-0"
            placeholder="Search"
            onChange={event =>
              this.setState({ searchString: event.target.value })
            }
          />
        </div>
        <div className="features-container">
          {this.props.cohortedTest.features!.length === 0 && (
            <small className="text-muted">No features found</small>
          )}
          {this.props.cohortedTest
            .features!.filter(feature => {
              if (this.state.searchString) {
                return feature.name
                  .toLowerCase()
                  .includes(this.state.searchString.toLowerCase());
              } else {
                return true;
              }
            })
            .map(feature => {
              return (
                <Feature
                  setCurrentFeature={this.setCurrentFeature.bind(this)}
                  key={feature.code}
                  feature={feature}
                  disabled={this.props.testState !== TestState.DISABLED}
                  readOnly={this.props.testState !== TestState.DISABLED}
                  deleteFeature={this.deleteFeature.bind(this)}
                />
              );
            })}
        </div>
        <div>
          <hr className="mt-0" />
        </div>
        <div className="create d-flex justify-content-between">
          <Input
            value={this.state.newFeatureName ?? ''}
            placeholder="Enter new feature name..."
            disabled={this.props.testState !== TestState.DISABLED}
            onChange={event =>
              this.setState({ newFeatureName: event.target.value })
            }
            onKeyDown={e => {
              if (e.key === 'Enter') {
                this.addFeature();
              }
            }}
          />
          <div
            className="submit"
            onClick={() => this.addFeature()}
            title="Create"
          >
            ↵
          </div>
        </div>
        {this.state.openFeature && (
          <FeaturesModal
            isOpen={!!this.state.openFeature}
            initialFeatureId={this.state.openFeature}
            onResolve={resolved => {
              if (resolved) {
              }
              this.setState({ openFeature: undefined });
            }}
            onClose={() => this.setState({ openFeature: undefined })}
          />
        )}
      </div>
    );
  }

  private renderCountryCodes() {
    if (!this.props.cohortedTest.country_codes) {
      return;
    }

    const countriesWithSettings = _.uniq(
      this.props.gameSettings
        ?.filter(
          settings =>
            settings.game_edition_id ===
            this.props.cohortedTest.game_edition_id,
        )
        .map(settings => settings.country),
    );

    const notAllSettingsExist = !this.props.cohortedTest.country_codes?.every(
      code => countriesWithSettings?.includes(code),
    );

    return (
      <>
        {this.props.loadingGameSettings && (
          <div className="d-flex align-items-center justify-content-center m-2">
            <Spinner />
            <small className="ml-2">Loading Game Settings...</small>
          </div>
        )}

        {notAllSettingsExist && !this.props.loadingGameSettings && (
          <UncontrolledAlert color="warning">
            <strong>Game Settings do not exist for all countries.</strong>{' '}
            {this.props.cohortedTest.status === TestState.RESOLVED
              ? 'Group changes might not have been applied to all countries'
              : 'Once resolved, group changes might not be applied to all countries'}
          </UncontrolledAlert>
        )}
        {this.props.countries && (
          <SelectField
            isMulti
            value={this.props.cohortedTest.country_codes!}
            map={this.props.countries}
            handleChange={this.handleChange.bind(this)}
            countries
          />
        )}
      </>
    );
  }

  private renderStatusPerCountry() {
    const colorMap: { [id: string]: string } = {
      disabled: 'danger',
      acquiring: 'warning',
      complete: 'success',
      active: 'success',
      paused: 'warning',
      resolved: 'primary',
      ended: 'secondary',
      pending_complete: 'success',
    };

    const statusMap: { [id: string]: string } = {
      disabled: 'Disabled',
      acquiring: 'Acquiring',
      complete: 'Resolved',
      active: 'Active',
      paused: 'Ended',
      resolved: 'Resolved',
      ended: 'Ended',
      pending_complete: 'Pending Resolved',
    };

    return (
      this.props.countries && (
        <>
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-end">
              <ButtonGroup
                className="align-items-end"
                disabled={
                  this.props.cohortedTest.status_per_country?.length === 0
                }
              >
                <Button
                  disabled={
                    this.props.cohortedTest.status_per_country?.length === 0 ||
                    this.props.testState === TestState.RESOLVED
                  }
                  size="sm"
                  onClick={event => {
                    event.stopPropagation();
                    this.setState({
                      statusPerCountryStatus: 'paused',
                      statusPerCountryModal: 'pauseForCountries',
                      statusPerCountryModalAction: 'End',
                      statusPerCountryModalPrompt:
                        'End test for the following countries:',
                      statusPerCountryModalTitle:
                        'End test for which countries?',
                    });
                  }}
                >
                  End Multiple
                </Button>
                <Button
                  disabled={
                    this.props.cohortedTest.status_per_country?.length === 0 ||
                    this.props.testState === TestState.RESOLVED
                  }
                  size="sm"
                  onClick={event => {
                    event.stopPropagation();
                    this.setState({
                      statusPerCountryStatus: 'complete',
                      statusPerCountryModal: 'completeForCountries',
                      statusPerCountryModalAction: 'Resolve',
                      statusPerCountryModalPrompt:
                        'Resolve test for the following countries:',
                      statusPerCountryModalTitle:
                        'Resolve test for which countries?',
                    });
                  }}
                >
                  Resolve Multiple
                </Button>
              </ButtonGroup>
            </div>
          </div>
          <hr />
          <div className="search d-flex justify-content-between align-items-center my-2 pb-2 text-muted">
            <small>
              <FontAwesomeIcon icon={faSearch} className="mr-2" />
            </small>
            <Input
              plaintext
              className="small outline-none p-0"
              placeholder="Search"
              onChange={event =>
                this.setState({
                  statusPerCountrySearchString: event.target.value,
                })
              }
            />
          </div>
          <div className="country-status-container">
            {this.props.cohortedTest.status_per_country &&
            this.props.cohortedTest.status_per_country.length !== 0
              ? this.props.cohortedTest.status_per_country
                  .filter(status => {
                    if (this.state.statusPerCountrySearchString) {
                      return this.props
                        .countries![status.country_code].toLowerCase()
                        .includes(
                          this.state.statusPerCountrySearchString.toLowerCase(),
                        );
                    } else {
                      return true;
                    }
                  })
                  .map(status => {
                    return (
                      <div className="country-status" key={status.country_code}>
                        <div
                          id="country-status-tooltip"
                          className="d-flex justify-content-between"
                        >
                          <div>
                            <span>
                              {this.props.countries![status.country_code]}{' '}
                            </span>
                            <Badge
                              size="sm"
                              pill
                              color={colorMap[status.status]}
                            >
                              {statusMap[status.status]}
                            </Badge>
                          </div>

                          <ButtonGroup size="sm" className="d-flex">
                            <Button
                              size="sm"
                              outline
                              id={`pause-icon-${status.country_code}`}
                              disabled={
                                status.status === 'paused' ||
                                status.status === 'complete'
                              }
                              onClick={event => {
                                event.stopPropagation();
                                this.setState({
                                  currentAction: 'pauseForCountry',
                                  singleCountryToUpdate: [status.country_code],
                                });
                              }}
                            >
                              <FontAwesomeIcon icon={faPause} title="End" />
                              <UncontrolledTooltip
                                target={`pause-icon-${status.country_code}`}
                              >
                                End
                              </UncontrolledTooltip>
                            </Button>
                            <Button
                              size="sm"
                              disabled={status.status !== 'paused'}
                              outline
                              id={`complete-icon-${status.country_code}`}
                              onClick={event => {
                                event.stopPropagation();
                                this.setState({
                                  currentAction: 'completeForCountry',
                                  currentActionAction: 'Resolve',
                                  currentActionPrompt: `Resolve test for ${
                                    this.props.countries![status.country_code]
                                  }?`,
                                  currentActionTitle: `Resolve test?`,
                                  singleCountryToUpdate: [status.country_code],
                                });
                              }}
                            >
                              <FontAwesomeIcon icon={faStop} title="Resolve" />
                            </Button>
                            <UncontrolledTooltip
                              target={`complete-icon-${status.country_code}`}
                            >
                              Resolve
                            </UncontrolledTooltip>
                          </ButtonGroup>
                        </div>
                        <div>
                          {status.activated_on && (
                            <>
                              <small className="text-muted">
                                Activated on:{' '}
                                {moment(status.activated_on!).format(
                                  'MMMM Do YYYY, HH:mm',
                                )}
                              </small>
                              <br />
                            </>
                          )}
                          {status.paused_on && (
                            <>
                              <small className="text-muted">
                                Ended on:{' '}
                                {moment(status.paused_on!).format(
                                  'MMMM Do YYYY, HH:mm',
                                )}
                              </small>
                              <br />
                            </>
                          )}
                          {status.completed_on && (
                            <>
                              <small className="text-muted">
                                Resolved on:{' '}
                                {moment(status.completed_on!).format(
                                  'MMMM Do YYYY, HH:mm',
                                )}
                              </small>
                              <br />
                            </>
                          )}
                        </div>
                      </div>
                    );
                  })
              : this.props.cohortedTest.country_codes
                  ?.filter(country_code => {
                    if (this.state.statusPerCountrySearchString) {
                      return this.props
                        .countries![country_code].toLowerCase()
                        .includes(
                          this.state.statusPerCountrySearchString.toLowerCase(),
                        );
                    } else {
                      return true;
                    }
                  })
                  .map(country_code => (
                    <div className="country-status" key={country_code}>
                      <div
                        id="country-status-tooltip"
                        className="d-flex justify-content-between"
                      >
                        <div>
                          <span>{this.props.countries![country_code]} </span>
                          <Badge size="sm" pill>
                            No Status
                          </Badge>
                        </div>
                        <ButtonGroup size="sm" className="d-flex">
                          <Button
                            size="sm"
                            outline
                            id={`pause-icon-${country_code}`}
                            disabled={true}
                          >
                            <FontAwesomeIcon icon={faPause} title="End" />
                            <UncontrolledTooltip
                              target={`pause-icon-${country_code}`}
                            >
                              End
                            </UncontrolledTooltip>
                          </Button>
                          <Button
                            size="sm"
                            disabled={true}
                            outline
                            id={`complete-icon-${country_code}`}
                          >
                            <FontAwesomeIcon icon={faStop} title="Resolve" />
                          </Button>
                          <UncontrolledTooltip
                            target={`complete-icon-${country_code}`}
                          >
                            Resolve
                          </UncontrolledTooltip>
                        </ButtonGroup>
                      </div>
                    </div>
                  ))}
          </div>
        </>
      )
    );
  }

  private renderConfirmationModal() {
    return (
      <ConfirmationModal
        title={this.state.currentActionTitle ?? 'Dispatch Action?'}
        isOpen={!!this.state.currentAction}
        confirmColor="primary"
        confirmContent={this.state.currentActionAction ?? 'Dispatch'}
        denyContent="Cancel"
        disablePrimaryButton={
          (this.state.currentAction === 'completeForCountries' ||
            this.state.currentAction === 'completeForCountry') &&
          !this.state.ActionGroupId
        }
        onResolve={resolved => {
          if (resolved) {
            this.executeAction(this.state.currentAction);
          }
          this.setState({
            currentAction: undefined,
            currentActionAction: undefined,
            currentActionPrompt: undefined,
            currentActionTitle: undefined,
            countryCodesToUpdate: [],
            ActionGroupId: undefined,
          });
        }}
        onClose={() =>
          this.setState({
            currentAction: undefined,
            currentActionAction: undefined,
            currentActionPrompt: undefined,
            currentActionTitle: undefined,
            countryCodesToUpdate: [],
            ActionGroupId: undefined,
          })
        }
      >
        {this.state.currentActionPrompt ??
          'Are you sure you want to dispatch this action?'}
        <br />
        {(this.state.currentAction === 'completeForCountry' ||
          this.state.currentAction === 'completeForCountries') &&
          this.renderGroupIdDropdown()}
        <strong>Any unsaved changes made to your test will be lost.</strong>
      </ConfirmationModal>
    );
  }

  generateGroupMap() {
    const groupsMap: { [x: string]: any } = {};
    this.props.cohortedTest.groups?.forEach(
      group =>
        (groupsMap[
          group.group_id
        ] = `Group ID: ${group.group_id} - ${group.description}`),
    );

    return groupsMap;
  }

  getDeadGroups() {
    return this.props.cohortedTest.groups
      ?.filter(group => group.user_share === 0)
      .map(group => group.group_id);
  }

  private renderGroupIdDropdown() {
    return (
      <>
        <small className="text-muted">Select a winning group ID:</small>
        <div className="w-100 my-2">
          <SelectField
            id="ActionGroupId"
            placeholder="Select group ID..."
            value={this.state.ActionGroupId ?? ''}
            handleChange={this.updateState.bind(this)}
            map={this.generateGroupMap()}
            disabled={this.getDeadGroups()}
          />
        </div>
      </>
    );
  }

  private renderStatusPerCountryModal() {
    const countryOptions: { [id: string]: string } = {};

    Object.keys(this.props.countries!).forEach(key => {
      // Get status for country
      const statusesToHide = [];
      const status = this.props.cohortedTest.status_per_country?.find(
        statusObj => statusObj.country_code === key,
      )?.status;

      //Filter country options based on status
      if (this.state.statusPerCountryStatus === 'complete') {
        statusesToHide.push('acquiring');
        statusesToHide.push('active');
      }

      if (
        status === this.state.statusPerCountryStatus ||
        (status === 'complete' &&
          this.state.statusPerCountryStatus === 'paused')
      ) {
        statusesToHide.push(status);
      }

      if (
        this.props.cohortedTest.country_codes?.includes(key) &&
        !statusesToHide.includes(this.state.statusPerCountryStatus!) &&
        !statusesToHide.includes(status)
      ) {
        countryOptions[key] = this.props.countries![key];
      }
    });

    return (
      <ConfirmationModal
        title={this.state.statusPerCountryModalTitle ?? 'Dispatch Action?'}
        isOpen={!!this.state.statusPerCountryModal}
        confirmColor="primary"
        confirmContent={this.state.statusPerCountryModalAction ?? 'Dispatch'}
        denyContent="Cancel"
        disablePrimaryButton={
          this.state.countryCodesToUpdate.length === 0 ||
          ((this.state.statusPerCountryStatus === 'complete' ||
            this.state.currentAction === 'completeForCountry') &&
            !this.state.ActionGroupId)
        }
        onResolve={resolved => {
          if (resolved) {
            this.executeAction(this.state.statusPerCountryModal);
          }
          this.setState({
            statusPerCountryModal: undefined,
            statusPerCountryModalAction: undefined,
            statusPerCountryModalPrompt: undefined,
            statusPerCountryModalTitle: undefined,
            countryCodesToUpdate: [],
            ActionGroupId: undefined,
          });
        }}
        onClose={() =>
          this.setState({
            statusPerCountryModal: undefined,
            statusPerCountryModalAction: undefined,
            statusPerCountryModalPrompt: undefined,
            statusPerCountryModalTitle: undefined,
            countryCodesToUpdate: [],
            ActionGroupId: undefined,
          })
        }
      >
        {this.state.statusPerCountryModalPrompt}
        <div className="w-100 my-2">
          <SelectField
            disabled={this.props.cohortedTest.status_per_country?.length === 0}
            isMulti
            id="countryCodesToUpdate"
            value={this.state.countryCodesToUpdate}
            map={countryOptions}
            handleChange={this.updateState.bind(this)}
            countries
            cohortedTestId={this.props.selectedTestId}
          />
        </div>
        {this.state.statusPerCountryStatus === 'complete' &&
          this.renderGroupIdDropdown()}
        <br />
        <strong>Any unsaved changes made to your test will be lost.</strong>
      </ConfirmationModal>
    );
  }
}

const mapStateToProps = (state: GlobalState) => {
  const cohortedTestsState = state.cohortedTestsPageReducer;

  return {
    error: getCohortedTestsError(cohortedTestsState),
    cohortedTests: getCohortedTests(cohortedTestsState),
    gameSettings: getGameSettings(cohortedTestsState),
    loadingGameSettings: getLoadingGameSettings(cohortedTestsState),
    pending: getCohortedTestsPending(cohortedTestsState),
    selectedTest: getSelectedTestId(cohortedTestsState),
    addFeaturePending: getAddFeaturePending(cohortedTestsState),
    newVariantCodes: getNewVariantCodes(cohortedTestsState),
    selectedTestId: getSelectedTestId(cohortedTestsState),
    gameEditionsMap: getGameEditionsMap(cohortedTestsState),
    loadingGameEditions: getLoadingGameEditions(cohortedTestsState),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<GlobalState, void, Action>,
) =>
  bindActionCreators(
    {
      updateSelectedTest: updateSelectedTest,
      executeAction: executeAction,
      changeTestContents: changeTestContents,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CohortedTestForm);
