import {
  faExclamationTriangle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Collapse, Input } from 'reactstrap';
import { Action, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { toast } from 'react-toastify';
import { CohortedTest, GlobalState } from '../../../model/cohorted-test.model';
import {
  addVariant,
  changeFeatureContents,
  changeVariantContents,
  deleteVariant,
  getNewVariantCodes,
  getSelectedTest,
  getSelectedTestId,
} from '../../../pages/CohortedTestsPage/cohortedTestsPageSlice';
import Toast from '../../Misc/Toast';
import { TestState } from '../CohortedTestForm';
import { getVariantInfo } from './operations';
import { getVariantsPending, setSelectedVariantId } from './featureSlice';

export interface Props {
  feature: any;
  setCurrentFeature: (featureId: string) => void;
  getVariantInfo: (testId: string, featureId: string) => void;
  disabled: boolean;
  deleteFeature: (featureId: string) => void;
  addVariant: (payload: any) => void;
  deleteVariant: (payload: any) => void;
  setSelectedVariantId: (variantId?: string) => void;
  newVariantCodes: { [id: string]: { feature: string; variant: string }[] };
  changeVariantContents: (payload: any) => void;
  changeFeatureContents: (feature: any) => void;
  selectedTestId: string;
  selectedTest?: CohortedTest;
  readOnly?: boolean;
}

export interface State {
  isOpen?: boolean;
  newVariantName?: string;
}

class Feature extends Component<Props, State> {
  state: State = {};

  addVariant(variantName?: string) {
    if (!variantName) {
      toast.error(
        <Toast icon={faExclamationTriangle}>
          Variant name cannot be empty
        </Toast>,
        {
          toastId: 'variant_name_empty',
        },
      );

      return;
    }

    const variants = [...this.props.feature.variants];

    const variantCodeNumbers = variants!.map((variant: any) =>
      parseInt(variant.code.replace('v', '')),
    );

    if (variantCodeNumbers.length === 0) {
      variantCodeNumbers.push(0);
    }

    // Get highest number
    const newVariantCode = `v${Math.max(...variantCodeNumbers) + 1}`;

    const newVariant = {
      code: newVariantCode,
      name: this.state.newVariantName,
    };

    const payload = {
      newVariant,
      featureId: this.props.feature.code,
    };

    this.props.addVariant(payload);

    this.setState({ newVariantName: '' });
  }

  render() {
    const { feature } = this.props;

    return (
      <div className="feature-container">
        <div
          className="feature"
          onClick={() => {
            this.setState({ isOpen: !this.state.isOpen });
          }}
        >
          <div className="d-flex align-items-center">
            <span className="feature-code">Feature {feature.code} </span>
            <Input
              value={feature.name}
              className="feature-name"
              disabled={this.props.selectedTest?.status !== TestState.DISABLED}
              onChange={event => {
                const newFeature = {
                  ...this.props.feature,
                };

                newFeature.name = event.target.value;
                this.props.changeFeatureContents(newFeature);
              }}
              onClick={event => {
                event.stopPropagation();
              }}
            />
          </div>
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              className="icon  mx-2"
              title="Delete Feature"
              onClick={event => {
                event.stopPropagation();

                if (!this.props.disabled) {
                  this.props.deleteFeature(feature.code);
                }
              }}
              icon={faTimes}
            />
          </div>
        </div>
        <Collapse isOpen={this.state.isOpen}>
          {feature.variants.map((variant: any) => (
            <div
              key={variant.code}
              className="variant d-flex justify-content-between align-items-center"
              onClick={event => {
                event.stopPropagation();

                if (
                  (
                    this.props.newVariantCodes[this.props.selectedTestId] ?? []
                  ).some(
                    variantCode =>
                      variantCode.feature === this.props.feature.code &&
                      variantCode.variant === variant.code,
                  )
                ) {
                  toast.error(
                    <Toast icon={faExclamationTriangle}>
                      You must save this test before you can modify this variant
                    </Toast>,
                    {
                      toastId: 'variant_not_saved',
                    },
                  );

                  return;
                }

                if (!this.props.disabled || this.props.readOnly) {
                  this.props.setCurrentFeature(feature.code);
                  this.props.setSelectedVariantId(variant.code);
                  this.props.getVariantInfo(
                    this.props.selectedTestId,
                    feature.code,
                  );
                }
              }}
            >
              <div className="d-flex align-items-center">
                <span className="variant-code">Variant {variant.code} </span>
                <Input
                  className="variant-name"
                  value={variant.name}
                  onClick={event => {
                    event.stopPropagation();
                  }}
                  disabled={
                    this.props.selectedTest?.status !== TestState.DISABLED
                  }
                  onChange={event => {
                    const newVariant = {
                      ...this.props.feature.variants.find(
                        (currentVariant: any) =>
                          currentVariant.code === variant.code,
                      ),
                    };

                    newVariant.name = event.target.value;
                    this.props.changeVariantContents({
                      variant: newVariant,
                      featureId: this.props.feature.code,
                    });
                  }}
                />
              </div>

              <div className="d-flex align-items-center">
                <FontAwesomeIcon
                  icon={faTimes}
                  title="Delete Variant"
                  className="icon mx-2 delete-icon"
                  onClick={event => {
                    event.stopPropagation();

                    if (!this.props.disabled) {
                      this.props.deleteVariant({
                        variantId: variant.code,
                        featureId: this.props.feature.code,
                      });
                    }
                  }}
                />
              </div>
            </div>
          ))}
          <div className="create d-flex justify-content-between mt-2">
            <Input
              className="ml-2"
              placeholder="Enter new variant name..."
              disabled={this.props.disabled}
              value={this.state.newVariantName ?? ''}
              onChange={event =>
                this.setState({ newVariantName: event.target.value })
              }
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  this.addVariant(this.state.newVariantName);
                }
              }}
            />
            <div
              className="submit"
              onClick={() => {
                if (!this.props.disabled) {
                  this.addVariant(this.state.newVariantName);
                }
              }}
              title="Create"
            >
              ↵
            </div>
          </div>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = (state: GlobalState) => {
  const featureState = state.featureReducer;
  const cohortedTestsState = state.cohortedTestsPageReducer;

  return {
    variantsPending: getVariantsPending(featureState),
    newVariantCodes: getNewVariantCodes(cohortedTestsState),
    selectedTestId: getSelectedTestId(cohortedTestsState),
    selectedTest: getSelectedTest(cohortedTestsState),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<GlobalState, void, Action>,
) =>
  bindActionCreators(
    {
      getVariantInfo: getVariantInfo,
      addVariant: addVariant,
      deleteVariant: deleteVariant,
      setSelectedVariantId: setSelectedVariantId,
      changeFeatureContents: changeFeatureContents,
      changeVariantContents: changeVariantContents,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Feature);
