import { createSlice } from '@reduxjs/toolkit';

export type FeatureState = {
  variants: any;
  variantsPending: boolean;
  selectedVariantId?: string;
  error?: any;
  savingPending: boolean;
  diffs?: { [id: string]: any };
  variantChangesMade: boolean;
};

const initialState: FeatureState = {
  variants: {},
  variantsPending: false,
  savingPending: false,
  variantChangesMade: false,
};

const featureSlice = createSlice({
  name: 'feature',
  initialState,
  reducers: {
    fetchVariantsPending(state) {
      state.variantsPending = true;
    },
    fetchVariantsSuccess(state, action) {
      state.variantsPending = false;

      state.variants = action.payload;
    },
    fetchVariantsError(state, error) {
      state.variantsPending = false;
      state.error = error;
    },
    setSelectedVariantId(state, action) {
      if (!action.payload) {
        state.selectedVariantId = undefined;
      } else {
        state.selectedVariantId = action.payload;
      }
    },
    addNewVariant(state, action) {
      state.variants[action.payload] = state.variants.control;
    },
    createSetting(state, action) {
      if (!state.selectedVariantId) {
        return;
      }

      state.variants[state.selectedVariantId].entities.settings[
        action.payload.id
      ] = action.payload;

      state.variantChangesMade = true;
    },
    updateVariant(state, action) {
      if (!state.selectedVariantId) {
        return;
      }

      state.variants[state.selectedVariantId].entities.settings[
        action.payload.id
      ] = action.payload;

      state.variantChangesMade = true;
    },
    setVariant(state, action) {
      state.variants[action.payload.id] = action.payload.data;

      state.variantChangesMade = true;
    },
    deleteSetting(state, action) {
      if (!state.selectedVariantId) {
        return;
      }

      delete state.variants[state.selectedVariantId].entities.settings[
        action.payload.id
      ];

      state.variantChangesMade = true;
    },
    clearVariantState(state) {
      state.variants = {};
    },
    saveVariantsPending(state) {
      state.savingPending = true;
    },
    saveVariantsSuccess(state) {
      state.savingPending = false;

      state.variantChangesMade = false;
    },
    saveVariantsError(state, error) {
      state.savingPending = false;
      state.error = error;
    },
    setVariantChangesMade(state, action) {
      state.variantChangesMade = action.payload;
    },
  },
});

export const {
  fetchVariantsPending,
  fetchVariantsSuccess,
  fetchVariantsError,
  setSelectedVariantId,
  updateVariant,
  setVariant,
  createSetting,
  deleteSetting,
  addNewVariant,
  saveVariantsPending,
  saveVariantsSuccess,
  saveVariantsError,
  clearVariantState,
  setVariantChangesMade,
} = featureSlice.actions;

export const getVariantsSuccess = (state: any) => state.variants;
export const getControlVariant = (state: any) => state.controlVariant;
export const getVariantsPending = (state: any) => state.variantsPending;
export const getVariantsError = (state: any) => state.error;
export const getVariantChangesMade = (state: any) => state.variantChangesMade;

export const getSelectedVariantId = (state: FeatureState) =>
  state.selectedVariantId;
export const getSelectedVariant = (state: FeatureState) => {
  if (!state.selectedVariantId) {
    return;
  }

  return state.variants[state.selectedVariantId];
};

export default featureSlice.reducer;
