// Endpoints dependant on URL
import axios from 'axios';

enum Environments {
  staging,
  live,
}

const serverURLs: Record<keyof typeof Environments, string> = {
  staging: 'https://uat-playphoto-admin.servers.kwalee.com:947/',
  live: 'https://admin-playphoto.servers.kwalee.com:948/',
};

const environmentNames: Record<keyof typeof Environments, string> = {
  staging: 'Staging',
  live: 'Live',
};

export function getEnvironment() {
  const envKey = (process.env.REACT_APP_ENV ??
    'staging') as keyof typeof Environments;

  return environmentNames[envKey];
}

export function getServerURL() {
  const envKey = (process.env.REACT_APP_ENV ??
    'staging') as keyof typeof Environments;

  return serverURLs[envKey];
}

export const getContextUrl = () => {
  return (
    window.location.protocol +
    '//' +
    window.location.hostname +
    ':' +
    window.location.port
  );
};

const API_URL = getServerURL();

let sessionId: string;
let email: string;
let gameId: string;

export const axiosConfig = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

function getRequestAdditions() {
  return {
    interface_id: `optimus_${gameId}`,
    game_id: gameId,
    session_id: sessionId,
    user_email: email,
  };
}

export function getData<T = any>(uri: string, data?: {}) {
  gameId = localStorage.getItem(`game_id_${getContextUrl()}`)!;
  sessionId =
    localStorage.getItem('session_id') ??
    localStorage.getItem(`session_id_${API_URL}${gameId}`)!;
  email = localStorage.getItem('user_id')!;

  return axiosConfig
    .get<T>(uri, {
      params: {
        ...data,
        ...getRequestAdditions(),
      },
    })
    .then(res => {
      return res.data;
    });
}

export function postData<T = any>(uri: string, data: {} = {}) {
  const params = new URLSearchParams();

  for (const key in data) {
    let val = (data as any)[key];
    if (typeof val === 'object') {
      val = JSON.stringify(val);
    }

    params.append(key, val);
  }

  const additions = getRequestAdditions();
  for (const key in additions) {
    let val = (additions as any)[key];
    if (typeof val === 'object') {
      val = JSON.stringify(val);
    }

    params.append(key, val);
  }

  return axiosConfig
    .post<T>(uri, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    })
    .then(res => {
      return res.data;
    });
}
