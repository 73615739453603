import { createAsyncThunk } from '@reduxjs/toolkit';
import { TestState } from '../../components/CohortedTestForm/CohortedTestForm';
import { CohortedTest } from '../../model/cohorted-test.model';
import { getData, postData } from '../../network/request';
import isNullOrUndefined from '../../util/isNullOrUndefined';
import {
  updateTestError,
  updateTestPending,
  updateTestSuccess,
} from './cohortedTestsPageSlice';
import {
  addNewTestError,
  addNewTestPending,
  addNewTestSuccess,
  deleteTestError,
  deleteTestPending,
  deleteTestSuccess,
  executeActionError,
  executeActionPending,
  executeActionSuccess,
  fetchCohortedTestsError,
  fetchCohortedTestsPending,
  fetchCohortedTestsSuccess,
} from './cohortedTestsPageSlice';

export const fetchCohortedTests = () => {
  return (dispatch: any) => {
    dispatch(fetchCohortedTestsPending());
    getData<{ cohorted_tests: CohortedTest[]; error?: any }>(
      '/optimus/cohorted_tests',
    )
      .then(res => {
        if (res.error) {
          throw res.error;
        }

        res.cohorted_tests.forEach(test => {
          if (isNullOrUndefined(test.status)) {
            test.status = TestState.DISABLED;
          }
        });

        res.cohorted_tests.sort((a, b) => {
          const objectIdAArray = a.object_id!.split('_');
          const objectIdA = parseInt(objectIdAArray[objectIdAArray.length - 1]);
          const objectIdBArray = b.object_id!.split('_');
          const objectIdB = parseInt(objectIdBArray[objectIdBArray.length - 1]);

          return objectIdA - objectIdB;
        });

        dispatch(fetchCohortedTestsSuccess(res.cohorted_tests));

        return res.cohorted_tests;
      })
      .catch(error => {
        dispatch(fetchCohortedTestsError(error));
      });
  };
};

export const addNewTest = (newTest: Partial<CohortedTest>) => {
  return (dispatch: any) => {
    dispatch(addNewTestPending());
    postData<{ cohorted_test: CohortedTest; error?: any }>(
      'optimus/cohorted_tests',
      newTest,
    )
      .then(res => {
        if (res.error) {
          throw res.error;
        }

        if (isNullOrUndefined(res.cohorted_test.status)) {
          res.cohorted_test.status = TestState.DISABLED;
        }

        dispatch(addNewTestSuccess(res.cohorted_test));
      })
      .catch(error => {
        dispatch(addNewTestError(error));
      });
  };
};

export const deleteTest = (testId: string) => {
  return (dispatch: any) => {
    dispatch(deleteTestPending());
    postData(`/optimus/cohorted_tests/${testId}/delete`)
      .then(res => {
        if (res.error) {
          throw res.error;
        }
        dispatch(deleteTestSuccess(res.cohorted_test));

        return res.cohorted_test;
      })
      .catch(error => {
        dispatch(deleteTestError(error));
      });
  };
};

export const updateSelectedTest = (test: CohortedTest) => {
  return (dispatch: any) => {
    dispatch(updateTestPending());
    postData(`/optimus/cohorted_tests/${test.object_id}/update`, test)
      .then(res => {
        if (res.error) {
          throw res.error;
        }
        dispatch(updateTestSuccess(res.cohorted_test));

        return res.cohorted_tests;
      })
      .catch(error => {
        dispatch(updateTestError(error));
      });
  };
};

export const executeAction = (
  testId: string,
  data: Partial<{
    country_codes: string[];
    status: string;
    winning_group_id: string;
    generate_groups: boolean;
  }>,
  successMessage?: string,
  errorMessage?: string,
) => {
  return (dispatch: any) => {
    dispatch(executeActionPending());
    let postUrl = `optimus/cohorted_tests/${testId}/action`;

    // If status is included in data send to custom endpoint with status
    if (data.hasOwnProperty('status')) {
      postUrl = postUrl + `/status`;
    }

    postData(postUrl, data)
      .then(res => {
        if (res.error) {
          throw res.error;
        }

        dispatch(
          executeActionSuccess({
            test: res.cohorted_test,
            successMessage,
          }),
        );

        return res.cohorted_tests;
      })
      .catch(error => {
        dispatch(executeActionError({ error, errorMessage }));
      });
  };
};

export const fetchGameEditions = createAsyncThunk(
  'gameEditions/fetch',
  async () => {
    const response = await getData('admin/game-editions');

    return response.game_editions;
  },
);

export const fetchGameSettings = createAsyncThunk(
  'gameSettings/fetch',
  async () => {
    const response = await getData('admin/versioned-settings');

    return response.versioned_settingss;
  },
);
