import {
  faChevronDown,
  faClipboard,
  faEye,
  faEyeSlash,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import React, { Component, ReactNode } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import isNullOrUndefined from '../../util/isNullOrUndefined';
import { AppendFromClipboardModal } from '../AppendFromClipboardModal';
import IOrderable, { isOrderable } from './IOrderable';
import CustomCheckbox from './CustomCheckbox';
import { FontAwesomeIcon } from './FontAwesomeIcon';
import LazyLoadedImage from './LazyLoadedImage';
import Spinner, { SpinnerColor, SpinnerSize } from './Spinner';

type SingleSelectOptionResult<T> = T | undefined;
type MultiSelectOptionResult<T> = T[];
type SelectOptionResult<T> =
  | SingleSelectOptionResult<T>
  | MultiSelectOptionResult<T>;

interface Props<T> {
  /** Whether to use the smaller variant of the advanced selector */
  small?: boolean;
  /** Extra Classes */
  className?: string;
  /** All the options to choose from, if lazy loading, the default options to show */
  options?: T[];
  /**
   * Enables lazy loading of options, this function is called whenever new options
   * are needed and replaces the previous options array entirely.
   *
   * Return false if nothing else to pull.
   * */
  lazyLoad?: (currentOptions: T[]) => T[];
  /** Sort options */
  sort?: boolean;
  /** All the currently selected options */
  selected?:
    | SelectOptionResult<T>
    | null
    | (() => SelectOptionResult<T> | null);
  /** Called when an option (or multiple options) are selected/deselected */
  onOptionSelected?: (
    option: SelectOptionResult<T>,
    selected: boolean,
    newSelected: SelectOptionResult<T>,
  ) => void;
  /** Whether the dropdown should be closed when a selection is made */
  closeOnSelection?: boolean;
  /** Whether or not this select supports multiple selections at once */
  multiselect?: boolean;
  /** Whether this dropdown is disabled */
  disabled?: boolean;
  /** Should you be able to clear the input? */
  nullable?: boolean;
  /** Whether this select is a blacklist or a whitelist */
  blacklist?: boolean;
  /** Ability to turn this whitelist filter in to a blacklist */
  blacklistToggle?: boolean;
  /** Called when the blacklist mode is toggled */
  onBlacklistToggle?: (blacklist: boolean) => void;
  /** Name of these options in a group */
  optionPlural?: string;
  /** Get the key of the option */
  getOptionKey: (option: T) => string;
  /** Get the display name of the option */
  getOptionLabel: (option: T) => string;
  /** Placeholder text */
  placeholder?: string;
  /** Overrides the rendering of the active selections */
  fieldRenderer?: () => ReactNode;
  /** Prevents any further propagation of events, e.g. if select is within another element with an onClick property **/
  stopPropagation?: boolean;
  /** Whether this select is searchable */
  searchable?: boolean;
  /**
   * Enables the ability to run other code instead of searching, allowing for things
   * like API based search with large data sets.
   * */
  search?: (query: string) => T[];
  /** Delay search by x milliseconds after user finishes typing */
  searchDebounce?: number;
  /** Provides icons for options */
  icons?: { [key: string]: string } | ((key: string) => string);
  /** Mapping of button name to array of values to select. Displayed as buttons at the top of the dropdown. */
  selectionGroups?: { [id: string]: string[] };
  /** The order for the selection groups to show */
  selectionGroupsOrder?: string[];
  /** Cohorted Test Specific Prop: The id of the currently selected cohorted test to be used when importing options from clipboard */
  cohortedTestId?: string;
}

interface State<T> {
  expanded: boolean;
  search: {
    query: string;
    results?: T[];
  };
  singleSelectHeight: number;
  options?: T[];
  lazyLoading: boolean;
  rightAlign?: boolean;
  appendRemoveModal?: string;
}

class AdvancedSelect<T = any> extends Component<Props<T>, State<T>> {
  state: State<T> = {
    expanded: false,
    search: {
      query: '',
    },
    singleSelectHeight: 15,
    lazyLoading: false,
  };

  private selectRef = React.createRef<HTMLDivElement>();
  private singleSelectRef = React.createRef<HTMLDivElement>();
  private searchRef = React.createRef<HTMLInputElement>();

  private dropdownContainerRef?: HTMLDivElement;
  private lastOptionRef?: HTMLDivElement;
  private lazyLoadObserver?: IntersectionObserver;

  private lazyLoadComplete = false;

  componentDidMount() {
    this.lazyLoadOptions();
  }

  componentDidUpdate(
    prevProps: Readonly<Props<T>>,
    prevState: Readonly<State<T>>,
  ) {
    // Update height of single select
    if (this.singleSelectRef.current) {
      const height = this.singleSelectRef.current.clientHeight;
      if (height !== this.state.singleSelectHeight) {
        this.setState({ singleSelectHeight: height });
      }
    }

    // Close dropdown if disabled
    if (this.props.disabled && this.state.expanded) {
      this.toggleDropdown(false);
    }

    // Focus search field on expand
    if (!prevState.expanded && this.state.expanded && this.searchRef.current) {
      this.searchRef.current.focus();
    }
  }

  async lazyLoadOptions() {
    if (
      !this.props.lazyLoad ||
      this.lazyLoadComplete ||
      this.state.lazyLoading ||
      this.state.search.query.trim().length > 0
    ) {
      return;
    }

    this.setState({ lazyLoading: true });

    let options: T[] = [];
    if (this.state.options) {
      options = [...this.state.options];
    }

    let result: T[] | false | undefined;
    try {
      const r = this.props.lazyLoad(options);

      result = r;
    } catch (e) {
      console.error(e);
    }

    this.setState({ lazyLoading: false });

    if (result === undefined) {
      return;
    }

    if (result === false) {
      this.lazyLoadComplete = true;

      return;
    }

    options = result;

    this.setState({ options });
  }

  async search(query?: string) {
    let results: T[] | undefined;

    if (query) {
      if (this.props.search) {
        // Custom search filter
        const result = this.props.search(query);

        results = result;
      } else {
        // Normal search filter
        results = this.getOptions().filter(option => {
          if (
            this.props
              .getOptionKey(option)
              .toLowerCase()
              .includes(query)
          ) {
            return true;
          }

          return this.props
            .getOptionLabel(option)
            .toLowerCase()
            .includes(query);
        });
      }
    }

    this.setState(prev => ({
      search: {
        ...prev.search,
        results,
      },
    }));
  }

  getOptionPlural() {
    return this.props.optionPlural ? this.props.optionPlural : 'Options';
  }

  onDocumentClickHide = (event: MouseEvent) => {
    if (this.state.expanded && this.selectRef.current) {
      if (this.selectRef.current.contains(event.target as Node)) {
        return;
      }
    }

    this.toggleDropdown(false);
  };

  toggleDropdown = (isExpanded?: boolean) => {
    const { disabled } = this.props;
    const { expanded } = this.state;
    let newExpanded = isExpanded;
    if (disabled) {
      if (!expanded) {
        return;
      }

      newExpanded = false;
    } else if (newExpanded === undefined) {
      newExpanded = !expanded;
    } else if (newExpanded === expanded) {
      return;
    }

    this.setState({ expanded: newExpanded }, () => {
      let rightAlign = false;
      if (this.selectRef.current && this.searchRef.current) {
        if (
          this.selectRef.current.getBoundingClientRect().x +
            this.searchRef.current.getBoundingClientRect().width >
          document.body.offsetWidth
        ) {
          rightAlign = true;
        }
      }

      this.setState({ rightAlign });
    });

    if (newExpanded) {
      document.documentElement.addEventListener(
        'click',
        this.onDocumentClickHide,
      );
    } else {
      this.setState({ search: { query: '' } });

      document.documentElement.removeEventListener(
        'click',
        this.onDocumentClickHide,
      );
    }
  };

  getOptions() {
    let options: T[];

    if (this.state.options) {
      options = this.state.options;
    } else if (this.props.options) {
      options = this.props.options;
    } else {
      options = [];
    }

    return options;
  }

  getVisibleOptions() {
    let options: T[];

    if (this.state.search.results) {
      options = this.state.search.results;
    } else {
      options = this.getOptions();
    }

    return options;
  }

  getSelected(): SelectOptionResult<T> {
    if (this.props.selected) {
      const headless =
        typeof this.props.selected === 'function'
          ? (this.props.selected as any)()
          : this.props.selected;

      if (this.props.multiselect) {
        return Array.isArray(headless) ? headless : [headless];
      }

      return headless;
    }

    return this.props.multiselect ? [] : undefined;
  }

  isSelected(option: T) {
    if (this.props.multiselect) {
      return (this.getSelected() as MultiSelectOptionResult<T>).some(o =>
        this.matches(o, option),
      );
    }

    return this.matches(
      this.getSelected() as SingleSelectOptionResult<T>,
      option,
    );
  }

  updateSearch(query: string) {
    this.setState(prev => ({ search: { ...prev.search, query: query } }));
  }

  matches(
    option: SingleSelectOptionResult<T>,
    option2: SingleSelectOptionResult<T>,
  ) {
    if (option === undefined) {
      return option2 === undefined;
    }
    if (option2 === undefined) {
      return false;
    }

    return this.props.getOptionKey(option) === this.props.getOptionKey(option2);
  }

  setOptionSelected = (option: SelectOptionResult<T>, selected: boolean) => {
    const currentlySelected = this.getSelected();

    let newSelected: SelectOptionResult<T>;
    if (this.props.multiselect) {
      const cs = currentlySelected as MultiSelectOptionResult<T>;

      const toUpdate = (Array.isArray(option) ? option : [option]).filter(
        option => cs.some(o => this.matches(option, o)) !== selected,
      ) as MultiSelectOptionResult<T>;

      if (toUpdate.length < 1) {
        return;
      }

      if (selected) {
        newSelected = [...cs, ...toUpdate];
      } else {
        newSelected = cs.filter(
          option => !toUpdate.some(o => this.matches(option, o)),
        );
      }
    } else {
      newSelected = option;
    }

    if (this.props.closeOnSelection) {
      this.toggleDropdown(false);
    }

    if (this.props.onOptionSelected) {
      this.props.onOptionSelected(option, selected, newSelected);
    }
  };

  selectAllOptions(selected: boolean) {
    const allOptions = this.getOptions();

    if (this.props.closeOnSelection) {
      this.toggleDropdown(false);
    }

    if (this.props.onOptionSelected) {
      if (selected) {
        this.props.onOptionSelected(allOptions, selected, allOptions);
      } else {
        this.props.onOptionSelected(allOptions, selected, []);
      }
    }
  }

  updateDropdownContainerRef(el: HTMLDivElement | null) {
    if (this.lazyLoadObserver) {
      this.lazyLoadObserver.disconnect();
      this.lazyLoadObserver = undefined;
    }

    this.dropdownContainerRef = el || undefined;

    if (this.dropdownContainerRef) {
      this.lazyLoadObserver = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (!entry.isIntersecting) {
              return;
            }

            this.lazyLoadOptions();
          });
        },
        {
          root: this.dropdownContainerRef,
          rootMargin: `0px 0px ${this.dropdownContainerRef.clientHeight *
            2}px 0px`,
        },
      );

      if (this.lastOptionRef) {
        this.lazyLoadObserver.observe(this.lastOptionRef);
      }
    }
  }

  updateLastOptionRef(el: HTMLDivElement | null) {
    if (this.lastOptionRef && this.lazyLoadObserver) {
      this.lazyLoadObserver.unobserve(this.lastOptionRef);
    }

    this.lastOptionRef = el || undefined;

    if (this.lastOptionRef && this.lazyLoadObserver) {
      this.lazyLoadObserver.observe(this.lastOptionRef);
    }
  }

  selectOptions(options?: string[]) {
    if (!options) {
      return;
    }

    if (this.props.closeOnSelection) {
      this.toggleDropdown(false);
    }

    const allOptions = this.getOptions();
    const selectedOptions = allOptions.filter(optionObject =>
      //@ts-ignore
      options.includes(optionObject.value),
    );

    if (this.props.onOptionSelected) {
      this.props.onOptionSelected(selectedOptions, true, selectedOptions);
    }
  }

  importOptionsFromClipboard(clipText: string, append?: boolean) {
    const options = clipText.split(',');
    // Check if required format
    if (options[0] !== this.props.cohortedTestId) {
      return;
    }
    let optionsToSelect = options;
    // If append is true
    if (append) {
      // Get currently selected and concatenate
      //@ts-ignore
      const selected = this.getSelected()?.map(item => item.value);
      optionsToSelect = selected.concat(options);
    }
    // Select options
    this.selectOptions(optionsToSelect);
  }

  removeClipboardOptions(clipText: string) {
    // Extract clipboard values
    let options = clipText.split(',');
    // Check if required format
    if (options[0] !== this.props.cohortedTestId) {
      return;
    }
    // Get currently selected and remove clipboard options
    //@ts-ignore
    const selected = this.getSelected()?.map(item => item.value);
    options = selected.filter((option: string) => !options.includes(option));
    // Select options
    this.selectOptions(options);
  }

  render() {
    return (
      <div
        ref={this.selectRef}
        className={classNames([
          'advanced-select',
          this.props.className,
          {
            disabled: this.props.disabled,
            'advanced-select-sm': this.props.small,
          },
        ])}
        onClick={event => {
          if (this.props.stopPropagation) {
            event.stopPropagation();
          }
          this.toggleDropdown();
        }}
      >
        {this._renderField()}

        {this.state.expanded && this._renderDropdown()}
      </div>
    );
  }

  private _renderField() {
    if (this.props.fieldRenderer) {
      return this.props.fieldRenderer();
    }

    let hasOptionsSelected = false;
    const selected = this.getSelected();
    if (selected !== undefined) {
      if (Array.isArray(selected)) {
        if (selected.length > 0) {
          hasOptionsSelected = true;
        }
      } else {
        hasOptionsSelected = true;
      }
    }

    return (
      <div
        className={classNames('as-field', {
          'as-options-selected': hasOptionsSelected,
        })}
      >
        <AppendFromClipboardModal
          isOpen={!!this.state.appendRemoveModal}
          append={this.state.appendRemoveModal === 'append'}
          onClose={() =>
            this.setState({
              appendRemoveModal: undefined,
            })
          }
          importOptionsFromClipboard={val =>
            this.importOptionsFromClipboard(val ?? '', true)
          }
          removeClipboardOptions={val => this.removeClipboardOptions(val)}
        />

        {this._renderSelectedOptions()}

        <div className="as-field-buttons">
          {this.props.blacklistToggle && (
            <div
              className="as-field-button blacklist"
              title={`Current Mode: ${
                this.props.blacklist ? 'Blacklist' : 'Whitelist'
              }`}
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();

                if (this.props.onBlacklistToggle) {
                  this.props.onBlacklistToggle(!this.props.blacklist);
                }
              }}
            >
              <FontAwesomeIcon
                icon={this.props.blacklist ? faEyeSlash : faEye}
              />
            </div>
          )}
          {this.props.nullable && (
            <div
              className="as-field-button clear"
              title="Clear"
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();
                this.setOptionSelected(this.getSelected(), false);
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
          )}

          <span className="as-field-buttons-separator" />

          <div
            className={classNames([
              'as-field-button',
              { 'as-button-flipY': this.state.expanded },
            ])}
          >
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        </div>
      </div>
    );
  }

  private _renderSelectedOptions() {
    const selected = this.getSelected();

    return (
      <div
        className="as-field-select singleselect"
        style={{ minHeight: `${this.state.singleSelectHeight}px` }}
      >
        {this._renderSelectedOption(selected)}
      </div>
    );
  }

  private _renderSelectedOption = (option: SelectOptionResult<T>) => {
    let text;
    const className = ['as-field-select-option'];
    if (option) {
      if (Array.isArray(option)) {
        if (option.length === 1) {
          text = this.props.getOptionLabel(option[0]);
        } else if (option.length > 1) {
          text = option.length + ' ' + this.getOptionPlural();
        }
      } else {
        text = this.props.getOptionLabel(option);
      }
    }

    if (text === undefined) {
      text =
        this.props.placeholder === undefined
          ? 'Select...'
          : this.props.placeholder;

      className.push('text-muted');
    }

    return (
      <div
        ref={this.singleSelectRef}
        className={className.join(' ')}
        title={text}
      >
        {text}
      </div>
    );
  };

  private _renderDropdown() {
    let options = this.getVisibleOptions();
    const { selectionGroups, selectionGroupsOrder } = this.props;

    if (
      (this.props.sort === undefined || this.props.sort) &&
      !this.props.lazyLoad &&
      (!this.props.search || !this.state.search.results)
    ) {
      if (options.length > 0 && isOrderable(options[0])) {
        options = [...options].sort(
          (o1: any, o2: any) =>
            (o1 as IOrderable).order - (o2 as IOrderable).order,
        );
      } else {
        options = [...options].sort((o1, o2) =>
          this.props
            .getOptionLabel(o1)
            .localeCompare(this.props.getOptionLabel(o2)),
        );
      }
    }

    let isSearchable = true;
    if (!isNullOrUndefined(this.props.searchable)) {
      isSearchable = this.props.searchable;
    }

    return (
      <div
        className="as-dropdown"
        style={{ right: this.state.rightAlign ? 0 : undefined }}
      >
        <div id="paste-container" className="d-none" />
        {isSearchable && (
          <div
            className="as-dropdown-search"
            onClick={event => event.stopPropagation()}
          >
            <input
              ref={this.searchRef}
              type="text"
              placeholder="Search"
              value={this.state.search.query}
              onChange={event => this.updateSearch(event.target.value)}
            />
          </div>
        )}
        <div className="m-2">
          <ButtonGroup className="m-1">
            <Button
              size="sm"
              onClick={event => {
                event.stopPropagation();
                this.selectAllOptions(true);
              }}
            >
              Select all
            </Button>
            <Button
              size="sm"
              onClick={event => {
                event.stopPropagation();
                this.selectAllOptions(false);
              }}
            >
              Select none
            </Button>
          </ButtonGroup>
          {this.props.cohortedTestId && (
            <ButtonGroup className="m-1">
              <Button
                size="sm"
                onClick={event => {
                  event.stopPropagation();
                  this.setState({ appendRemoveModal: 'append' });
                }}
              >
                <FontAwesomeIcon icon={faClipboard} /> Append clipboard
              </Button>
              <Button
                size="sm"
                onClick={event => {
                  event.stopPropagation();
                  this.setState({ appendRemoveModal: 'remove' });
                }}
              >
                <FontAwesomeIcon icon={faClipboard} /> Remove clipboard
              </Button>
            </ButtonGroup>
          )}

          {selectionGroups &&
            Object.keys(selectionGroups)
              .sort((a, b) => {
                if (!selectionGroupsOrder) {
                  return 0;
                }

                return (
                  selectionGroupsOrder.indexOf(a) -
                  selectionGroupsOrder.indexOf(b)
                );
              })
              .map(key => (
                <Button
                  size="sm"
                  className="m-1"
                  key={key}
                  onClick={event => {
                    event.stopPropagation();
                    this.selectOptions(selectionGroups && selectionGroups[key]);
                  }}
                >
                  {key}
                </Button>
              ))}
        </div>

        <div
          ref={ref => this.updateDropdownContainerRef(ref)}
          className="as-dropdown-container"
        >
          {options.map(this._renderOption)}

          {this.state.lazyLoading && !this.state.search.results && (
            <div className="as-dropdown-container-option info">
              <div className="as-dropdown-container-option-label">
                <Spinner size={SpinnerSize.Small} color={SpinnerColor.Muted} />
              </div>
            </div>
          )}

          {this.state.search.results && this.state.search.results.length < 1 && (
            <div className="as-dropdown-container-option info">
              <div className="as-dropdown-container-option-label">
                No results found
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  private _renderOption = (option: T) => {
    const isSelected = this.isSelected(option);
    const label = this.props.getOptionLabel(option);
    const key = this.props.getOptionKey(option);

    const onClick = (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      this.setOptionSelected(option, !isSelected);
    };

    let icon: string | undefined;
    if (this.props.icons) {
      if (typeof this.props.icons === 'function') {
        icon = this.props.icons(key);
      } else {
        icon = this.props.icons[key];
      }
    }

    let iconElem;
    if (!isNullOrUndefined(icon)) {
      iconElem = (
        <LazyLoadedImage
          src={icon}
          width={18}
          height={18}
          parent={this.dropdownContainerRef}
        />
      );
    }

    return (
      <div
        ref={ref => this.updateLastOptionRef(ref)}
        key={key}
        className={classNames('as-dropdown-container-option', {
          'as-dropdown-active': isSelected && !this.props.multiselect,
        })}
        onClick={onClick}
      >
        <div className="as-dropdown-container-option-label">
          {this.props.multiselect ? (
            <CustomCheckbox
              inline
              id={`toggle-${key}`}
              label={
                <span className={isSelected ? 'font-weight-bold' : undefined}>
                  {iconElem} {label}
                </span>
              }
              checked={isSelected}
              onClick={onClick}
            />
          ) : (
            <span>
              {iconElem} {label}
            </span>
          )}
        </div>
      </div>
    );
  };
}

export default AdvancedSelect;
