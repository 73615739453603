import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { CardHeader, Label } from 'reactstrap';
import { PopoverState } from '../../CohortedTestForm/CohortedTestForm';
import { FieldContext } from '../Field';

export interface Props {
  id: string;
  title: string;
  disabled?: boolean;
  hoverText?: string;
  setPopover?: (state?: PopoverState) => void;
}

export const FieldHeader = (props: Props) => {
  const context = React.useContext(FieldContext)!;

  return (
    <CardHeader className="d-flex justify-content-between align-items-center">
      <Label>
        <strong>
          {props.title}
          {context.required && '*'}
        </strong>
      </Label>
      {props.hoverText && props.setPopover && (
        <div
          id={props.id}
          onMouseEnter={() =>
            props.setPopover!({
              target: props.id,
              hoverText: props.hoverText ?? '',
            })
          }
          onMouseLeave={() => props.setPopover!()}
        >
          <FontAwesomeIcon className="info-button" icon={faInfoCircle} />
        </div>
      )}
    </CardHeader>
  );
};
