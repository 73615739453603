import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { Action, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ToastTypes } from '../../enums/ToastTypes';
import { GlobalState } from '../../model/cohorted-test.model';
import {
  CohortedTestsState,
  showToast,
} from '../../pages/CohortedTestsPage/cohortedTestsPageSlice';
import { getVariantsSuccess } from '../CohortedTestForm/Feature/featureSlice';
import { denormalizeSettings } from '../CohortedTestForm/Feature/operations';

type Props = {
  variants?: any;
  variantKey: string;
  showToast: (state: { message: string; type: string }) => void;
};

function VariantExport(props: Props) {
  function onExport() {
    const variant = props.variants[props.variantKey];

    const settings = denormalizeSettings(
      variant.entities.settings,
      [variant.result],
      false,
    );

    navigator.clipboard
      .writeText(JSON.stringify(settings.settings))
      .then(() => {
        props.showToast({
          type: ToastTypes.SUCCESS,
          message: 'Exported to clipboard successfully!',
        });
      });
  }

  return (
    <Button color="primary" onClick={() => onExport()}>
      Export {props.variantKey}
    </Button>
  );
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<CohortedTestsState, void, Action>,
) =>
  bindActionCreators(
    {
      showToast: showToast,
    },
    dispatch,
  );

const mapStateToProps = (state: GlobalState) => {
  const featureState = state.featureReducer;

  return {
    variants: getVariantsSuccess(featureState),
  };
};

const VariantExportWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VariantExport);

export default VariantExportWrapper;
