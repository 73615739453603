import React, { Component } from 'react';
import { Spinner as Loader } from 'reactstrap';

export enum SpinnerType {
  Border = 'border',
  Grow = 'grow',
}

export enum SpinnerSize {
  Small = 'small',
  Normal = 'normal',
  Big = 'big',
}

export enum SpinnerColor {
  Primary = 'primary',
  Secondary = 'secondary',
  Light = 'light',
  Dark = 'dark',
  Muted = 'muted',
}

const spinnerSizes = {
  small: {
    width: '1rem',
    height: '1rem',
    borderWidth: '0.1rem',
  },
  normal: {
    width: '3rem',
    height: '3rem',
    borderWidth: '0.3rem',
  },
  big: {
    width: '6rem',
    height: '6rem',
    borderWidth: '0.5rem',
  },
};

interface ISpinnerProps {
  type?: SpinnerType;
  size?: SpinnerSize;
  color?: SpinnerColor;
}

class Spinner extends Component<ISpinnerProps, {}> {
  render() {
    const {
      type = SpinnerType.Border,
      size = SpinnerSize.Normal,
      color = SpinnerColor.Light,
    } = this.props;

    return (
      <Loader
        className="spinner"
        style={spinnerSizes[size]}
        color={color}
        type={type}
      />
    );
  }
}

export default Spinner;
