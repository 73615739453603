import {
  faCaretDown,
  faCaretRight,
  faEdit,
  faEllipsisV,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { cloneDeep } from 'lodash-es';
import React, { Component } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvidedDragHandleProps,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import {
  Badge,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  UncontrolledDropdown,
} from 'reactstrap';
import { Action, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { v4 as uuidv4 } from 'uuid';
import { ToastTypes } from '../../enums/ToastTypes';
import { GlobalState, ValueType } from '../../model/cohorted-test.model';
import {
  CohortedTestsState,
  showToast,
} from '../../pages/CohortedTestsPage/cohortedTestsPageSlice';
import { checkIfCollection } from '../../util/checkIfCollection';
import { DifferenceType } from '../../util/Diff';
import isNullOrUndefined from '../../util/isNullOrUndefined';
import { reorder } from '../../util/reorder';
import {
  createSetting,
  deleteSetting,
  getSelectedVariant,
  getSelectedVariantId,
  getVariantsSuccess,
  updateVariant,
} from '../CohortedTestForm/Feature/featureSlice';
import { denormalizeSettings } from '../CohortedTestForm/Feature/operations';

export interface Props {
  parentSettingId?: string;
  id: string;
  isExpanded: boolean;
  toggleExpand?: (expanded?: boolean) => void;
  topLevel?: boolean;
  variants?: any;
  variantKey: string;
  selectedVariantId?: string;
  selectedVariant?: any;
  updateVariant: (setting: any) => void;
  createSetting: (setting: any) => void;
  deleteSetting: (setting: any) => void;
  parentType: string;
  index?: number;
  readOnly: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
  diff: any;
  exportSetting?: (variant?: any) => void;
  showToast: (state: { message: string; type: ToastTypes }) => void;
}

export interface State {
  expandedChildren: string[];
  name: string;
  value?: any;
  addNewSettingAmount: number;
  duplicateSettingAmount: number;
  areChildrenVisible?: boolean;
  editingTitle?: boolean;
}

const InputType: { [id: string]: 'number' | 'text' | 'select' } = {
  int: 'number',
  double: 'number',
  string: 'text',
  boolean: 'select',
};

class VariantExpandableSetting extends Component<Props, State> {
  state: State = {
    expandedChildren: [],
    name: this.getSetting().name,
    value: this.getSetting().value,
    addNewSettingAmount: 1,
    duplicateSettingAmount: 1,
    areChildrenVisible: this.props.isExpanded,
  };

  componentDidMount(): void {
    if (!checkIfCollection(this.getSetting())) {
      this.toggleExpand(true);
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any,
  ): void {
    if (prevProps.variants !== this.props.variants) {
      this.setState({
        value: this.getSetting()?.value,
        name: this.getSetting()?.name,
      });
    }

    if (prevProps.isExpanded !== this.props.isExpanded) {
      if (this.props.isExpanded) {
        this.setState({ areChildrenVisible: true });
      } else {
        setTimeout(() => this.setState({ areChildrenVisible: false }), 300);
      }
    }
  }

  getSetting(isParent?: boolean) {
    return this.props.variants[this.props.variantKey].entities.settings[
      isParent ? this.props.parentSettingId! : this.props.id
    ];
  }

  getSettingById(id: string) {
    return this.props.variants[this.props.variantKey].entities.settings[id];
  }

  toggleExpand(expanded?: boolean) {
    if (!this.props.toggleExpand) {
      return;
    }

    this.props.toggleExpand(expanded);
  }

  expandSetting(isExpanded: boolean, name: string) {
    this.setState(prevState => {
      const expandedChildren = [...prevState.expandedChildren];

      if (isNullOrUndefined(isExpanded)) {
        isExpanded = !expandedChildren.includes(name);
      }

      if (isExpanded && expandedChildren.includes(name)) {
        return { expandedChildren };
      }

      if (isExpanded) {
        expandedChildren.push(name);
      } else {
        const index = expandedChildren.indexOf(name);
        expandedChildren.splice(index, 1);
      }

      return { expandedChildren };
    });
  }

  expandDirectChildren() {
    if (!this.props.isExpanded) {
      this.toggleExpand(true);
    }

    this.setState({ expandedChildren: this.getSetting().value });
  }

  collapseDirectChildren() {
    this.setState({ expandedChildren: [] });
  }

  createSetting() {
    // Clone setting
    let setting = {
      ...this.getSetting(),
    };

    // Define new setting
    for (let i = 0; i < this.state.addNewSettingAmount; i++) {
      // Create the new setting
      const newSetting = {
        id: uuidv4(),
        name: 'newSetting',
        type: 'string',
        value: '',
      };

      this.props.createSetting(newSetting);

      // Update setting children
      setting = this.addIdToChildrenArray(setting, newSetting.id);
    }

    this.props.updateVariant(setting);

    // Expand the setting if it is not already
    this.toggleExpand(true);
  }

  duplicateSetting() {
    let parentSetting = { ...this.getSetting(true) };

    // Create new setting with same contents but different id
    for (let i = 0; i < this.state.duplicateSettingAmount; i++) {
      const duplicateSetting = { ...this.getSetting() };

      duplicateSetting.id = uuidv4();
      this.props.createSetting(duplicateSetting);

      // Update setting children
      parentSetting = this.addIdToChildrenArray(
        parentSetting,
        duplicateSetting.id,
      );
    }

    this.props.updateVariant(parentSetting);
  }

  updateSetting() {
    // Grab setting values from state
    const { name, value } = this.state;

    const setting = { ...this.getSetting() };

    // Validation Checks
    if (name === setting.name && value === setting.value) {
      return;
    }

    setting.name = name;
    setting.value = value;

    if (
      isNaN(value) &&
      (setting.type === ValueType.INT || setting.type === ValueType.DOUBLE)
    ) {
      setting.value = 0;
    }

    // Dispatch redux action
    this.props.updateVariant(setting);
  }

  deleteSetting() {
    const setting = { ...this.getSetting() };
    let parentSetting = { ...this.getSetting(true) };

    // Update setting children
    parentSetting = this.removeIdFromChildrenArray(parentSetting, setting.id);
    this.props.updateVariant(parentSetting);

    this.props.deleteSetting(setting);
  }

  addIdToChildrenArray(setting: any, idToAdd: string) {
    const value = Array.from(setting.value);
    value.push(idToAdd);
    setting.value = value;

    return setting;
  }

  removeIdFromChildrenArray(setting: any, idToDelete: string) {
    const value = Array.from(setting.value);
    const index = value.findIndex(settingId => settingId === idToDelete);
    value.splice(index, 1);
    setting.value = value;

    return setting;
  }

  changeSettingType(type: ValueType) {
    const setting = { ...this.getSetting() };

    setting.value = this.getNewSettingInitialValue(type);
    this.setState({ value: this.getNewSettingInitialValue(type) });

    setting.type = type;

    this.props.updateVariant(setting);
  }

  getNewSettingInitialValue(type: ValueType) {
    let value;

    switch (type) {
      case ValueType.ARRAY:
        value = [];
        break;
      case ValueType.BOOLEAN:
        value = false;
        break;
      case ValueType.INT:
        value = 0;
        break;
      case ValueType.DOUBLE:
        value = 0.0;
        break;
      case ValueType.NULL:
        value = null;
        break;
      case ValueType.OBJECT:
        value = [];
        break;
      case ValueType.STRING:
        value = '';
        break;
      default:
        return;
    }

    return value;
  }

  onSettingDragEnd(result: DropResult) {
    // Dropped outside list
    if (!result.destination) {
      return;
    }

    const setting = { ...this.getSetting() };

    setting.value = reorder(
      setting.value,
      result.source.index,
      result.destination.index,
    );

    this.props.updateVariant(setting);
  }

  render() {
    // If a null setting is found don't render it
    if (!this.getSetting()) {
      return null;
    }

    const isCollection = checkIfCollection(this.getSetting());

    return (
      <div className={`setting setting-${this.getSetting().type}`}>
        {this.renderHeader()}
        {(this.props.topLevel || isCollection) && this.renderCollapsibleValue()}
      </div>
    );
  }

  private renderHeader() {
    const { diff } = this.props;
    const isCollection = checkIfCollection(this.getSetting());

    let color = '';

    switch (diff && diff.type) {
      case DifferenceType.MODIFIED:
        color = 'info';
        break;
      case DifferenceType.ADDITION:
        color = 'success';
        break;
      case DifferenceType.DELETION:
        break;
    }

    return (
      <div
        onClick={() => this.toggleExpand()}
        className={classNames(
          'setting-header',
          {
            'd-none': this.props.topLevel,
            'grab-hand':
              this.props.parentType === ValueType.ARRAY && !this.props.readOnly,
          },
          diff && diff.type,
        )}
        {...this.props.dragHandleProps}
      >
        <div className="heading-container">
          {isCollection && (
            <FontAwesomeIcon
              className={classNames('caret', {
                expanded: this.props.isExpanded,
              })}
              icon={faCaretRight}
            />
          )}
          {this.props.parentType === 'array' ? (
            <span className="heading read-only">{this.props.index}</span>
          ) : this.props.readOnly ? (
            <span className="heading read-only">{this.state.name}</span>
          ) : this.state.editingTitle ? (
            <>
              <Input
                className="heading"
                value={this.state.name}
                onChange={event => this.setState({ name: event.target.value })}
                disabled={
                  this.props.parentType === 'array' || this.props.readOnly
                }
                onClick={event => event.stopPropagation()}
                onBlur={() => this.updateSetting()}
              />
              <div
                className="inline-edit-button"
                title="Rename setting"
                onClick={event => {
                  event.stopPropagation();
                  this.setState({ editingTitle: false });
                }}
              >
                <FontAwesomeIcon className="inline-edit-icon" icon={faTimes} />
              </div>
            </>
          ) : (
            <>
              <span className="heading read-only">{this.state.name}</span>
              <div
                className="inline-edit-button"
                title="Exit renaming"
                onClick={event => {
                  event.stopPropagation();
                  this.setState({ editingTitle: true });
                  // Focus text box
                }}
              >
                <FontAwesomeIcon className="inline-edit-icon" icon={faEdit} />
              </div>
            </>
          )}
          {diff && diff.type !== DifferenceType.UNCHANGED && (
            <Badge className="ml-2" color={color}>
              {diff.type}
            </Badge>
          )}
        </div>
        {!isCollection && this.renderInlineValue()}
        <div className="right-column">
          {this.renderTypeDropdown()}
          {this.renderDropdown()}
        </div>
      </div>
    );
  }

  private renderTypeDropdown() {
    const setting = this.getSetting();

    return (
      <UncontrolledDropdown>
        <DropdownToggle
          className="dropdown-menu-icon d-flex align-items-center"
          tag="span"
          onClick={event => event.stopPropagation()}
          disabled={this.props.readOnly}
        >
          <Badge className="type-badge">
            {setting.type}
            {checkIfCollection(setting) && ` (${setting.value.length})`}{' '}
            {!this.props.readOnly && <FontAwesomeIcon icon={faCaretDown} />}
          </Badge>
        </DropdownToggle>
        <DropdownMenu>
          {Object.values(ValueType).map(type => {
            return (
              <DropdownItem
                key={type}
                disabled={setting.type === type}
                onClick={event => {
                  event.stopPropagation();
                  this.changeSettingType(type);
                }}
              >
                {type}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }

  private exportSetting(variant?: any) {
    if (!variant) {
      variant = cloneDeep(this.props.variants[this.props.variantKey]);
    }

    if (this.props.parentType === ValueType.ARRAY) {
      // Arrays should return everything because of ordering
      const parent = variant.entities.settings[this.props.parentSettingId!];
      for (const id of parent.value) {
        // Restore sub-data
        const setting = this.props.variants[this.props.variantKey].entities
          .settings[id];
        variant.entities.settings[id] = cloneDeep(setting);
      }

      this.props.exportSetting!(variant);

      return;
    }

    const setting = variant.entities.settings[this.props.id];

    if (
      this.props.parentType === ValueType.OBJECT &&
      this.props.parentSettingId
    ) {
      const parent = variant.entities.settings[this.props.parentSettingId];
      parent.value = [setting.id];
    }

    if (!this.props.topLevel) {
      this.props.exportSetting!(variant);

      return;
    }

    navigator.clipboard
      .writeText(
        JSON.stringify(
          denormalizeSettings(
            variant.entities.settings,
            [variant.result],
            false,
          ).settings,
        ),
      )
      .then(() => {
        this.props.showToast({
          type: ToastTypes.SUCCESS,
          message: 'Exported to clipboard successfully!',
        });
      });
  }

  private renderDropdown() {
    const isCollection = checkIfCollection(this.getSetting());

    return (
      <UncontrolledDropdown>
        <DropdownToggle
          className="dropdown-menu-icon d-flex align-items-center"
          tag="span"
          onClick={event => event.stopPropagation()}
        >
          <FontAwesomeIcon icon={faEllipsisV} className="my-1 ml-3 mr-2" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={event => {
              event.stopPropagation();
              this.exportSetting();
            }}
          >
            Export
          </DropdownItem>
          {!this.props.readOnly && isCollection && (
            <DropdownItem
              onClick={event => {
                event.stopPropagation();
                this.createSetting();
              }}
            >
              Add{' '}
              <Input
                type="number"
                step="1"
                max="100"
                min="1"
                value={this.state.addNewSettingAmount}
                onChange={event =>
                  this.setState({
                    addNewSettingAmount: parseInt(event.target.value),
                  })
                }
                onClick={event => event.stopPropagation()}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    this.createSetting();
                  }
                }}
              />
            </DropdownItem>
          )}
          {!this.props.readOnly && (
            <DropdownItem
              onClick={event => {
                event.stopPropagation();
                this.duplicateSetting();
              }}
            >
              Duplicate{' '}
              <Input
                type="number"
                step="1"
                max="100"
                min="1"
                value={this.state.duplicateSettingAmount}
                onChange={event =>
                  this.setState({
                    duplicateSettingAmount: parseInt(event.target.value),
                  })
                }
                onClick={event => event.stopPropagation()}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    this.duplicateSetting();
                  }
                }}
              />
            </DropdownItem>
          )}
          {isCollection &&
            (this.state.expandedChildren.length !==
              this.getSetting().value.length ||
              !this.props.isExpanded) && (
              <DropdownItem
                onClick={event => {
                  event.stopPropagation();
                  this.expandDirectChildren();
                }}
              >
                Expand direct children
              </DropdownItem>
            )}
          {isCollection &&
            this.state.expandedChildren.length !== 0 &&
            this.props.isExpanded && (
              <DropdownItem
                onClick={event => {
                  event.stopPropagation();
                  this.collapseDirectChildren();
                }}
              >
                Collapse direct children
              </DropdownItem>
            )}
          {!this.props.readOnly && (
            <>
              <DropdownItem divider />
              <DropdownItem onClick={() => this.deleteSetting()}>
                Delete
              </DropdownItem>
            </>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }

  private renderInlineValue() {
    const setting = { ...this.getSetting() };

    return (
      <>
        {setting.type === ValueType.BOOLEAN ? (
          <div className="setting-atomic-value">
            <Input
              value={this.state.value}
              type="select"
              onChange={event =>
                this.setState({
                  value: event.target.value === 'true',
                })
              }
              onBlur={() => this.updateSetting()}
              disabled={this.props.readOnly}
            >
              <option value="true">True</option>
              <option value="false">False</option>
            </Input>
          </div>
        ) : (
          <div className="setting-atomic-value">
            <Input
              value={this.state.value.toString()}
              type={InputType[setting.type]}
              step={
                setting.type === ValueType.INT
                  ? '1'
                  : setting.type === ValueType.DOUBLE
                  ? '0.01'
                  : '0'
              }
              onChange={event =>
                this.setState({
                  value:
                    setting.type === ValueType.INT
                      ? parseInt(event.target.value ?? 0)
                      : setting.type === ValueType.DOUBLE
                      ? parseFloat(event.target.value ?? 0)
                      : event.target.value,
                })
              }
              onBlur={() => this.updateSetting()}
              disabled={this.props.readOnly}
            />
          </div>
        )}
      </>
    );
  }

  private renderCollapsibleValue() {
    const isCollection = checkIfCollection(this.getSetting());

    const setting = { ...this.getSetting() };

    if (isCollection && setting.type !== 'array') {
      const value = Array.from(setting.value);
      value.sort((a: any, b: any) => {
        const aSettingName = this.props.variants[this.props.variantKey].entities
          .settings[a].name;
        const bSettingName = this.props.variants[this.props.variantKey].entities
          .settings[b].name;

        return aSettingName.localeCompare(bSettingName);
      });

      setting.value = value;
    }

    if (setting.type === ValueType.NULL) {
      return;
    }

    return (
      <Collapse
        className={classNames('setting-value', {
          'pl-3': !this.props.topLevel,
        })}
        isOpen={this.props.isExpanded}
      >
        {isCollection && this.state.areChildrenVisible ? (
          <DragDropContext
            onDragEnd={dropResult => this.onSettingDragEnd(dropResult)}
          >
            <Droppable
              droppableId="droppable"
              isDropDisabled={
                setting.type !== ValueType.ARRAY || this.props.readOnly
              }
            >
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {setting.value.map((key: any, index: number) => {
                    return (
                      <Draggable
                        key={key}
                        draggableId={key}
                        index={index}
                        isDragDisabled={
                          setting.type !== ValueType.ARRAY ||
                          this.props.readOnly
                        }
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className={classNames([
                              { dragging: snapshot.isDragging },
                            ])}
                            style={provided.draggableProps.style}
                          >
                            <SettingWrapper
                              dragHandleProps={provided.dragHandleProps}
                              key={key}
                              id={key}
                              index={index}
                              variantKey={this.props.variantKey}
                              parentSettingId={this.props.id}
                              isExpanded={this.state.expandedChildren.includes(
                                key,
                              )}
                              toggleExpand={isExpanded =>
                                this.expandSetting(isExpanded!, key)
                              }
                              parentType={setting.type}
                              readOnly={this.props.readOnly}
                              diff={
                                this.props.diff &&
                                this.props.diff.children[
                                  this.getSettingById(key).name
                                ]
                              }
                              exportSetting={this.exportSetting.bind(this)}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : setting.type === ValueType.BOOLEAN ? (
          <div className="setting-atomic-value">
            <Input
              value={this.state.value}
              type="select"
              onChange={event =>
                this.setState({
                  value: event.target.value === 'true',
                })
              }
              onBlur={() => this.updateSetting()}
              disabled={this.props.readOnly}
            >
              <option value="true">True</option>
              <option value="false">False</option>
            </Input>
          </div>
        ) : (
          <div className="setting-atomic-value">
            <Input
              value={this.state.value.toString()}
              type={InputType[setting.type]}
              step={
                setting.type === ValueType.INT
                  ? '1'
                  : setting.type === ValueType.DOUBLE
                  ? '0.01'
                  : '0'
              }
              onChange={event =>
                this.setState({
                  value:
                    setting.type === ValueType.INT
                      ? parseInt(event.target.value ?? 0)
                      : setting.type === ValueType.DOUBLE
                      ? parseFloat(event.target.value ?? 0)
                      : event.target.value,
                })
              }
              onBlur={() => this.updateSetting()}
              disabled={this.props.readOnly}
            />
          </div>
        )}
      </Collapse>
    );
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<CohortedTestsState, void, Action>,
) =>
  bindActionCreators(
    {
      updateVariant: updateVariant,
      createSetting: createSetting,
      deleteSetting: deleteSetting,
      showToast: showToast,
    },
    dispatch,
  );

const mapStateToProps = (state: GlobalState) => {
  const featureState = state.featureReducer;

  return {
    variants: getVariantsSuccess(featureState),
    selectedVariantId: getSelectedVariantId(featureState),
    selectedVariant: getSelectedVariant(featureState),
  };
};

const SettingWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VariantExpandableSetting);
export default SettingWrapper;
