import classNames from 'classnames';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import { Action, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { CohortedTest, GlobalState } from '../../model/cohorted-test.model';
import {
  getCohortedTests,
  getSelectedTest,
  getSelectedTestId,
} from '../../pages/CohortedTestsPage/cohortedTestsPageSlice';
import { newGetDiff } from '../../util/Diff';
import isNullOrUndefined from '../../util/isNullOrUndefined';
import { TestState } from '../CohortedTestForm/CohortedTestForm';
import {
  addNewVariant,
  clearVariantState,
  getSelectedVariant,
  getSelectedVariantId,
  getVariantChangesMade,
  getVariantsPending,
  getVariantsSuccess,
  setSelectedVariantId,
  setVariantChangesMade,
} from '../CohortedTestForm/Feature/featureSlice';
import {
  getVariantInfo,
  saveVariants,
} from '../CohortedTestForm/Feature/operations';
import Spinner, { SpinnerColor } from '../Misc/Spinner';
import SelectField from '../SelectField/SelectField';
import SettingWrapper from '../VariantExpandableSetting/VariantExpandableSetting';
import VariantExport from '../VariantExport/VariantExport';
import BatchVariantImport from '../VariantImport/BatchVariantImport';
import VariantImport from '../VariantImport/VariantImport';

interface Props {
  isOpen?: boolean;
  title?: ReactNode;
  confirmColor?: string;
  confirmContent?: ReactNode;
  denyColor?: string;
  denyContent?: ReactNode;
  onClose?: () => void;
  onResolve?: (resolved: boolean) => void;
  variants?: any;
  variantsPending: boolean;
  selectedTestId: string;
  selectedTest?: CohortedTest;
  selectedVariantId?: string;
  selectedVariant?: any;
  initialFeatureId: string;
  getVariantInfo: (testId: string, featureId: string) => void;
  setSelectedVariantId: (variantId?: string) => void;
  addNewVariant: (variantId: string) => void;
  saveVariants: (testId: string, featureCode: string, variants: any) => void;
  clearVariantState: () => void;
  variantChangesMade: boolean;
  setVariantChangesMade: (value: boolean) => void;
}

interface State {
  controlSettings?: any;
  currentFeatureCode?: string;
  currentFeatureName?: string;
  diffs?: { [id: string]: any };
}

class FeaturesModal extends Component<Props, State> {
  state: State = {
    currentFeatureCode: this.props.initialFeatureId,
    currentFeatureName: this.props.selectedTest?.features?.find(
      feature => feature.code === this.props.initialFeatureId,
    ).name,
  };

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.variants && prevProps.variants !== this.props.variants) {
      this.getDiffs();
    }
    if (
      isNullOrUndefined(this.props.selectedVariantId) &&
      Object.keys(this.props.variants).length !== 0
    ) {
      this.props.setSelectedVariantId(Object.keys(this.props.variants)[0]);
      this.setState({
        currentFeatureName: this.props.selectedTest?.features?.find(
          feature => feature.code === this.state.currentFeatureCode,
        ).name,
      });
    }
  }

  componentWillUnmount(): void {
    this.setState({
      currentFeatureName: undefined,
      currentFeatureCode: undefined,
    });
    this.props.clearVariantState();
  }

  getDiffs() {
    const diffs: { [id: string]: any } = {};

    if (Object.keys(this.props.variants).length >= 1) {
      Object.keys(this.props.variants)
        .filter(key => key !== 'control')
        .forEach(key => {
          diffs[key] = newGetDiff(
            this.props.variants['control'],
            this.props.variants[key],
          );
        });
    }

    this.setState({ diffs });
  }

  addVariant() {
    const newVariantName = `v${Object.keys(this.props.variants).filter(
      variant => variant !== 'control',
    ).length + 1}`;

    this.props.addNewVariant(newVariantName);
    this.props.setSelectedVariantId(newVariantName);
  }

  handleCancel = () => {
    const { onResolve } = this.props;

    if (onResolve) {
      onResolve(false);
    }
  };

  handleConfirm = () => {
    const { onResolve } = this.props;

    if (onResolve) {
      onResolve(true);
    }
  };

  handleClose = () => {
    const { onClose } = this.props;

    if (this.props.variantChangesMade) {
      const confirm = window.confirm(
        'There are unsaved changes made to variants, are you sure you would like to exit?',
      );

      if (confirm) {
        this.props.setSelectedVariantId();

        if (onClose) {
          onClose();
        }
      }

      this.props.setVariantChangesMade(false);
    } else {
      this.props.setSelectedVariantId();

      if (onClose) {
        onClose();
      }
    }
  };

  handleChange(stateName: string, value: any) {
    //@ts-ignore
    this.setState({ [stateName]: value }, () => {
      if (stateName === 'currentFeatureCode') {
        this.props.setSelectedVariantId();
        this.props.getVariantInfo(
          this.props.selectedTestId,
          this.state.currentFeatureCode!,
        );
      }
    });
  }

  render() {
    const features = this.props.selectedTest?.features;
    const featureMap: { [id: string]: string } = {};

    // Generate map for select box from features array
    if (features) {
      features.map(feature => (featureMap[feature.code] = feature.name));
    }

    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.handleClose}
        className="optimus-modal"
      >
        <ModalHeader toggle={this.handleClose}>
          <div className="edit-feature-header d-flex justify-content-between align-items-center w-100">
            <span>Edit Feature</span>
            {featureMap && this.state.currentFeatureCode && (
              <SelectField
                className="feature-name-dropdown"
                value={this.state.currentFeatureCode}
                id="currentFeatureCode"
                map={featureMap}
                handleChange={this.handleChange.bind(this)}
              />
            )}
          </div>
        </ModalHeader>
        <ModalBody>
          {this.props.variantsPending ? (
            <div className="text-center">
              <Spinner color={SpinnerColor.Muted} />
            </div>
          ) : (
            <>
              <div className="d-flex flex-column w-100">
                {Object.keys(this.props.variants).length === 0 && (
                  <Alert color="danger">
                    Failed to fetch variants. Ensure a Versioned Game Settings
                    exists for this test's countries and game edition.
                  </Alert>
                )}

                {Object.keys(this.props.variants).length !== 0 && (
                  <>
                    <div className="text-right mt-2">
                      <BatchVariantImport
                        readOnly={
                          this.props.selectedTest?.status !== TestState.DISABLED
                        }
                      />
                    </div>

                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classNames('tab', {
                            active: this.props.selectedVariantId === 'control',
                          })}
                          onClick={() =>
                            this.props.setSelectedVariantId('control')
                          }
                        >
                          Control
                        </NavLink>
                      </NavItem>
                      {Object.keys(this.props.variants)
                        .filter(variant => variant !== 'control')
                        .sort((a, b) => {
                          const aVal: any = a.replace('v', '');
                          const bVal: any = b.replace('v', '');

                          return aVal - bVal;
                        })
                        .map(key => {
                          return (
                            <NavItem key={key}>
                              <NavLink
                                className={classNames('tab', {
                                  active: this.props.selectedVariantId === key,
                                })}
                                onClick={() =>
                                  this.props.setSelectedVariantId(key)
                                }
                              >
                                {key}
                              </NavLink>
                            </NavItem>
                          );
                        })}
                    </Nav>
                  </>
                )}
                <TabContent activeTab={this.props.selectedVariantId}>
                  {Object.keys(this.props.variants).length !== 0 &&
                    this.state.diffs &&
                    this.props.selectedVariantId &&
                    Object.keys(this.props.variants).map(key => {
                      return (
                        <TabPane key={key} tabId={key}>
                          <div className="text-right mt-2">
                            <VariantExport variantKey={key} />

                            {key !== 'control' && (
                              <VariantImport
                                variantKey={key}
                                readOnly={
                                  this.props.selectedTest?.status !==
                                  TestState.DISABLED
                                }
                              />
                            )}
                          </div>

                          <div className="py-3 settings-container">
                            <SettingWrapper
                              id={this.props.variants![key as any].result}
                              variantKey={key}
                              isExpanded={true}
                              topLevel={true}
                              parentType="object"
                              readOnly={
                                key === 'control' ||
                                this.props.selectedTest?.status !==
                                  TestState.DISABLED
                              }
                              diff={this.state.diffs && this.state.diffs[key]}
                            />
                          </div>
                        </TabPane>
                      );
                    })}
                </TabContent>
              </div>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={
              Object.keys(this.props.variants).length === 0 ||
              this.props.selectedTest?.status !== TestState.DISABLED
            }
            onClick={() => {
              this.props.saveVariants(
                this.props.selectedTestId,
                this.state.currentFeatureCode!,
                this.props.variants,
              );
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state: GlobalState) => {
  const featureState = state.featureReducer;
  const cohortedTestsState = state.cohortedTestsPageReducer;

  return {
    variants: getVariantsSuccess(featureState),
    selectedVariantId: getSelectedVariantId(featureState),
    selectedVariant: getSelectedVariant(featureState),
    variantsPending: getVariantsPending(featureState),
    cohortedTests: getCohortedTests(cohortedTestsState),
    selectedTestId: getSelectedTestId(cohortedTestsState),
    selectedTest: getSelectedTest(cohortedTestsState),
    variantChangesMade: getVariantChangesMade(featureState),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<GlobalState, void, Action>,
) =>
  bindActionCreators(
    {
      getVariantInfo: getVariantInfo,
      setSelectedVariantId: setSelectedVariantId,
      addNewVariant: addNewVariant,
      saveVariants: saveVariants,
      clearVariantState: clearVariantState,
      setVariantChangesMade: setVariantChangesMade,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(FeaturesModal);
