export const getContextUrl = () => {
  return (
    window.location.protocol +
    '//' +
    window.location.hostname +
    ':' +
    window.location.port
  );
};

export const getGameID = (game?: string) => {
  if (game) {
    setGameID(game);
  }

  let game_id = window.sessionStorage.getItem('game_id_' + getContextUrl());
  if (!game_id) {
    game_id = window.localStorage.getItem('game_id_' + getContextUrl());
  }

  return game_id;
};

const setGameID = (id: string) => {
  window.sessionStorage.setItem('game_id_' + getContextUrl(), id);
  window.localStorage.setItem('game_id_' + getContextUrl(), id);
};
