import React, { useEffect } from 'react';
import './style/index.scss';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { getContextUrl } from './network/request';
import CohortedTests from './pages/CohortedTestsPage/CohortedTestsPage';

type GameAdminAuth = {
  game_id: string;
  session_id: string;
  user_id: string;
  interface_id: string;
};

function App() {
  window.addEventListener('message', ({ origin, data }) => {
    const allowedOrigins = [
      'http://127.0.0.1:6677',
      'http://uat-optimus.servers.kwalee.com:55667',
      'http://uat-optimus.servers.kwalee.com',
      'https://uat-optimus.servers.kwalee.com',
      'https://www.uat-optimus.servers.kwalee.com',
      'https://uat-optimus.servers.kwalee.com:55667',
      'http://optimus.servers.kwalee.com:55667',
      'https://optimus.servers.kwalee.com:55667',
      'http://optimus.servers.kwalee.com',
      'https://optimus.servers.kwalee.com',
      'https://www.optimus.servers.kwalee.com',
      'https://amplify-uat-optimus.servers.kwalee.com',
      'https://www.amplify-uat-optimus.servers.kwalee.com',
    ];

    if (allowedOrigins.includes(origin)) {
      // Receive auth data from parent
      const authData: GameAdminAuth = JSON.parse(data);

      const oldGameId = localStorage.getItem(`game_id_${getContextUrl()}`);
      const oldSessionId = localStorage.getItem('session_id');

      // Load into local storage for that url
      authData.game_id &&
        localStorage.setItem(`game_id_${getContextUrl()}`, authData.game_id);
      authData.game_id &&
        sessionStorage.setItem(`game_id_${getContextUrl()}`, authData.game_id);
      authData.session_id &&
        localStorage.setItem('session_id', authData.session_id);
      authData.user_id && localStorage.setItem('user_id', authData.user_id);
      authData.interface_id &&
        localStorage.setItem('interface_id', authData.interface_id);

      if (
        authData.game_id !== oldGameId ||
        authData.session_id !== oldSessionId
      ) {
        // Reload page if auth data is updated or game is changed
        window.location.reload();
      }
    }
  });

  useEffect(() => {
    // onLoad postMessage to parent saying ready to receive credentials
    window.parent.postMessage('loaded', '*');
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/cohorted-tests" component={CohortedTests} />
        <Redirect to="/cohorted-tests" />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
