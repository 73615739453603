import {
  faCheckCircle,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Alert, Toast } from 'reactstrap';
import { Action, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import CohortedTestForm from '../../components/CohortedTestForm/CohortedTestForm';
import Sidebar from '../../components/Sidebar/Sidebar';
import Spinner, { SpinnerColor } from '../../components/Misc/Spinner';
import { ToastTypes } from '../../enums/ToastTypes';
import { getData } from '../../network/request';
import {
  currentToastSelector,
  setConfig,
  setSelectedTest,
} from './cohortedTestsPageSlice';
import {
  fetchCohortedTests,
  fetchGameEditions,
  fetchGameSettings,
} from './operations';
import {
  clearError,
  CohortedTestsState,
  getCohortedTests,
  getCohortedTestsError,
  getCohortedTestsPending,
  getSelectedTest,
  getSelectedTestId,
  showToast,
} from './cohortedTestsPageSlice';

export interface Props extends CohortedTestsState {
  fetchCohortedTests: () => void;
  setSelectedTest: (objectId: string) => void;
}

const CohortedTestsPage = (props: Props) => {
  const dispatch = useDispatch();
  const { fetchCohortedTests, error, setSelectedTest } = props;
  const currentToast = useSelector(currentToastSelector);

  const [countries, setCountries] = useState({});

  useEffect(() => {
    const getAdminConfig = () => {
      getData('/admin/config').then(res => {
        const countryMap: { [id: string]: string } = {};
        res.config.settings.countries.forEach(
          (country: any) => (countryMap[country.value] = country.label),
        );
        setCountries(countryMap);
        dispatch(setConfig(res.config));
      });
    };
    fetchCohortedTests();
    getAdminConfig();
    dispatch(fetchGameEditions());
    dispatch(fetchGameSettings());
  }, [dispatch, fetchCohortedTests]);

  useEffect(() => {
    if (currentToast) {
      switch (currentToast.type) {
        case ToastTypes.ERROR:
          toast.error(
            <Toast icon={faExclamationTriangle}>{currentToast.message}</Toast>,
            {
              toastId: uuidv4(),
            },
          );
          break;
        case ToastTypes.SUCCESS:
          toast.success(
            <Toast icon={faCheckCircle}>{currentToast.message}</Toast>,
            {
              toastId: uuidv4(),
            },
          );
          break;
        case ToastTypes.WARN:
          toast.warn(
            <Toast icon={faExclamationTriangle}>{currentToast.message}</Toast>,
            {
              toastId: uuidv4(),
            },
          );
          break;
      }
      dispatch(showToast(undefined));
    }
  }, [currentToast, dispatch]);

  if (props.fetchingPending) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100 w-100 mt-5">
        <Spinner color={SpinnerColor.Muted} />
      </div>
    );
  }

  if (props.cohortedTests.length === 0) {
    return (
      <div className="page-wrapper">
        <div className="form-wrapper">
          {error && <Alert color="danger">No Tests Found</Alert>}
          <h2 className="page-heading">Cohorted Tests</h2>
        </div>
        <Sidebar countries={countries} />
      </div>
    );
  }

  let selectedTest = props.selectedTest;

  if (!selectedTest) {
    selectedTest = props.cohortedTests[0];
    setSelectedTest(selectedTest.object_id!);
  }

  return (
    <div className="page-wrapper">
      <div className="form-wrapper">
        {error && error.message && (
          <Alert
            color="danger"
            isOpen={!!error.message}
            toggle={() => dispatch(clearError())}
          >
            {error.message}
          </Alert>
        )}
        <h2 className="page-heading">Cohorted Tests</h2>
        <div className="mb-2">
          <small>{selectedTest?.object_id}</small>
        </div>

        {selectedTest ? (
          <CohortedTestForm
            cohortedTest={selectedTest}
            testState={selectedTest.status}
            key={selectedTest.object_id}
            countries={countries}
          />
        ) : (
          <div>No test selected</div>
        )}
      </div>
      <Sidebar countries={countries} />
    </div>
  );
};

const mapStateToProps = (state: {
  cohortedTestsPageReducer: CohortedTestsState;
}) => {
  const cohortedTestsState = state.cohortedTestsPageReducer;

  return {
    error: getCohortedTestsError(cohortedTestsState),
    cohortedTests: getCohortedTests(cohortedTestsState),
    fetchingPending: getCohortedTestsPending(cohortedTestsState),
    selectedTestId: getSelectedTestId(cohortedTestsState),
    selectedTest: getSelectedTest(cohortedTestsState),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<CohortedTestsState, void, Action>,
) =>
  bindActionCreators(
    {
      fetchCohortedTests: fetchCohortedTests,
      setSelectedTest: setSelectedTest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CohortedTestsPage);
