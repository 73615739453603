import { OptionTypeBase } from 'react-select';

export function toReactSelectObject<
  T extends { [id: string]: string },
  K extends keyof T,
  D extends (keyof T)[]
>(map: T, key: K, disabled?: D): OptionTypeBase {
  return {
    label: map[key],
    value: key,
    isDisabled: disabled?.includes(key),
  };
}
