import {
  faBars,
  faExclamationTriangle,
  faPlus,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { Action, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { CohortedTest } from '../../model/cohorted-test.model';
import {
  CohortedTestsState,
  getAddNewTestPending,
  getCohortedTests,
  getCohortedTestsError,
  getCohortedTestsPending,
  getGameSettings,
  getLoadingGameSettings,
  getSelectedTestId,
  setSelectedTest,
} from '../../pages/CohortedTestsPage/cohortedTestsPageSlice';
import {
  addNewTest,
  deleteTest,
} from '../../pages/CohortedTestsPage/operations';
import AddNewCohortedTest from '../AddNewCohortedTest/AddNewCohortedTest';
import { TestState } from '../CohortedTestForm/CohortedTestForm';
import { ConfirmationModal } from '../ConfirmationModal/ConfirmationModal';

export interface Props extends Partial<CohortedTestsState> {
  setSelectedTest: (objectId: string) => void;
  addNewTest: (newTest: Partial<CohortedTest>) => void;
  deleteTest: (testId: string) => void;
  countries: { [id: string]: string };
}

export interface State {
  isOpen?: boolean;
  isAddNewModalOpen?: boolean;
  deleteModalId?: string;
}

export const TestStatus: { [id: string]: string } = {
  disabled: 'Disabled',
  active: 'Active',
  paused: 'Ended',
  complete: 'Resolved',
  pending_delete: 'Pending Deletion',
  pending_active: 'Pending Activation',
  pending_pause: 'Pending Ending',
  pending_complete: 'Pending Resolved',
  partial_pause: 'Partially Ended',
};

class Sidebar extends Component<Props, State> {
  state: State = {
    isOpen: true,
  };

  openMenu(event: React.MouseEvent) {
    event.stopPropagation();
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    if (!this.props.cohortedTests) {
      return (
        <>
          <p>No tests found</p>
          <Button>Add new test</Button>
        </>
      );
    }

    const tests: { [id: string]: CohortedTest[] } = {
      disabled: [],
      pending_active: [],
      active: [],
      partial_pause: [],
      paused: [],
      pending_complete: [],
      complete: [],
      pending_delete: [],
    };

    const testOrder = [
      'disabled',
      'pending_active',
      'active',
      'partial_pause',
      'paused',
      'pending_complete',
      'complete',
      'pending_delete',
    ];

    for (const test of this.props.cohortedTests) {
      const status = test.status ?? '';

      let arr = tests[status];
      if (!arr) {
        arr = tests[status] = [];
      }

      arr.push(test);
    }

    return (
      <>
        <div className={classNames('sidebar', { open: this.state.isOpen })}>
          <Button
            color="link"
            onClick={event => this.openMenu(event)}
            className="sidebar-button"
          >
            <FontAwesomeIcon icon={faBars} />
          </Button>
          <div className="sidebar-wrapper">
            {Object.entries(tests)
              .sort(
                ([keyA, arrA], [keyB, arrB]) =>
                  testOrder.indexOf(keyA) - testOrder.indexOf(keyB),
              )
              .map(([key, arr], index) => {
                return (
                  (arr.length !== 0 || key === 'disabled') && (
                    <React.Fragment key={key}>
                      <span className="test-category-title">
                        {TestStatus[key] || 'Undefined'} Tests
                      </span>
                      <hr className="mt-0 mb-3 mr-5 ml-1" />
                      <ul className="test-list">
                        {arr.map(test => {
                          const countriesWithSettings = _.uniq(
                            this.props.gameSettings
                              ?.filter(
                                settings =>
                                  settings.game_edition_id ===
                                  test.game_edition_id,
                              )
                              .map(settings => settings.country),
                          );

                          const notAllSettingsExist = !test.country_codes?.every(
                            code => countriesWithSettings?.includes(code),
                          );

                          return (
                            <li
                              className={classNames('test', {
                                selected:
                                  test.object_id === this.props.selectedTestId,
                              })}
                              key={test.object_id}
                              onClick={() =>
                                this.props.setSelectedTest(test.object_id!)
                              }
                            >
                              <div className="d-flex align-items-center">
                                {notAllSettingsExist &&
                                  !this.props.loadingGameSettings && (
                                    <>
                                      <span
                                        id="missingSettingsIcon"
                                        className="mr-2"
                                      >
                                        <FontAwesomeIcon
                                          icon={faExclamationTriangle}
                                        />
                                      </span>
                                      <UncontrolledTooltip target="missingSettingsIcon">
                                        <strong>
                                          Game Settings do not exist for all
                                          countries.
                                        </strong>{' '}
                                        {test.status === TestState.RESOLVED
                                          ? 'Group changes might not have been applied to all countries'
                                          : 'Once resolved, group changes might not be applied to all countries'}
                                      </UncontrolledTooltip>
                                    </>
                                  )}

                                <span className="test-list-title pr-2">
                                  <strong>{test.name}</strong> <br />{' '}
                                  {test.object_id}
                                </span>
                                {!(
                                  test.status === TestState.PENDING_DELETE
                                ) && (
                                  <Button
                                    color="none"
                                    size="sm"
                                    disabled={
                                      test.status !== TestState.DISABLED &&
                                      test.status !== TestState.RESOLVED
                                    }
                                    className="delete-btn btn-primary"
                                    onClick={event => {
                                      event.stopPropagation();
                                      this.setState({
                                        deleteModalId: test.object_id,
                                      });
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faTimes} />
                                  </Button>
                                )}
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                      {key === 'disabled' && (
                        <div className="d-flex justify-content-center">
                          <Button
                            onClick={() =>
                              this.setState({ isAddNewModalOpen: true })
                            }
                            color="link"
                            size="sm"
                          >
                            <FontAwesomeIcon icon={faPlus} /> Add
                          </Button>
                        </div>
                      )}
                    </React.Fragment>
                  )
                );
              })}
          </div>
        </div>
        <ConfirmationModal
          title="Delete Test?"
          isOpen={!!this.state.deleteModalId}
          confirmColor="danger"
          confirmContent="Delete"
          denyContent="Cancel"
          onResolve={resolved => {
            if (resolved) {
              this.props.deleteTest(this.state.deleteModalId!);
            }
            this.setState({ deleteModalId: undefined });
          }}
          onClose={() => this.setState({ deleteModalId: undefined })}
        >
          Are you sure you want to delete this test?
        </ConfirmationModal>
        <AddNewCohortedTest
          isOpen={this.state.isAddNewModalOpen}
          confirmContent="Create"
          denyContent="Cancel"
          countries={this.props.countries}
          onResolve={(resolved: any) => {
            // TODO keep modal visible until creation pending is false

            this.setState({ isAddNewModalOpen: false });
          }}
          onClose={() => this.setState({ isAddNewModalOpen: false })}
        />
      </>
    );
  }
}

const mapStateToProps = (state: {
  cohortedTestsPageReducer: CohortedTestsState;
}) => {
  const cohortedTestsState = state.cohortedTestsPageReducer;

  return {
    error: getCohortedTestsError(cohortedTestsState),
    cohortedTests: getCohortedTests(cohortedTestsState),
    gameSettings: getGameSettings(cohortedTestsState),
    loadingGameSettings: getLoadingGameSettings(cohortedTestsState),
    pending: getCohortedTestsPending(cohortedTestsState),
    selectedTestId: getSelectedTestId(cohortedTestsState),
    creationPending: getAddNewTestPending(cohortedTestsState),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<CohortedTestsState, void, Action>,
) =>
  bindActionCreators(
    {
      setSelectedTest: setSelectedTest,
      addNewTest: addNewTest,
      deleteTest: deleteTest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
