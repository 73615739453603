import { configureStore } from '@reduxjs/toolkit';
import React from 'react';
import { render } from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import App from './App';
import { rootReducer } from './rootReducer';
import * as serviceWorker from './serviceWorker';

const store = configureStore({ reducer: rootReducer, middleware: [thunk] });

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

if (process.env.NODE_ENV === 'development') {
  window.addEventListener('message', event => {
    if (event.data?.type === 'update_session') {
      localStorage.setItem(`session_id`, event.data.session);
    }
  });
}
