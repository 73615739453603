import { ValueType } from '../model/cohorted-test.model';
import { unique } from './unique';

export enum DifferenceType {
  UNCHANGED = 'unchanged',
  ADDITION = 'addition',
  DELETION = 'deletion',
  MODIFIED = 'modified',
}

type Diff = { [key: string]: DifferenceEntry };
export interface DifferenceEntry {
  type: DifferenceType;
  //changed: { [id: keyof NestedSetting]: DifferenceType };
  children: Diff;
}
export function getDiff(a?: any, b?: any) {
  const diff: DifferenceEntry = {
    type: DifferenceType.UNCHANGED,
    children: {},
  };
  if (!a) {
    // a doesn't have it
    if (!b) {
      // b doesn't have it either, it's unchanged technically
      diff.type = DifferenceType.UNCHANGED;

      return diff;
    }
    // Must have been added
    diff.type = DifferenceType.ADDITION;
  } else if (!b) {
    // b doesnt have it, must have been removed
    diff.type = DifferenceType.DELETION;
  }
  // If same time, must be modified
  const sameType = a?.type === b?.type;
  if (a && b && !sameType) {
    diff.type = DifferenceType.MODIFIED;
  }
  switch (a ? a.type : b!.type) {
    case ValueType.ARRAY:
      // Compare arrays
      const aArr = a?.value ?? [];
      const bArr = b?.value ?? [];
      // Process arrays
      for (let i = 0; i < Math.max(aArr.length, bArr.length); i++) {
        const aObj = aArr[i];
        const bObj = bArr[i];
        const childDiff = getDiff(aObj, bObj);
        diff.children[i] = childDiff;
        if (sameType && childDiff.type !== DifferenceType.UNCHANGED) {
          diff.type = DifferenceType.MODIFIED;
        }
      }
      break;
    case ValueType.OBJECT:
      // Compare objects
      const aObj = a?.value ?? {};
      const bObj = b?.value ?? {};
      // Process objects
      const aKeys = Object.keys(aObj);
      const bKeys = Object.keys(bObj);
      for (const key of [...aKeys, ...bKeys].filter(unique)) {
        const aVal = aObj[key];
        const bVal = bObj[key];
        const childDiff = getDiff(aVal, bVal);
        diff.children[key] = childDiff;
        if (sameType && childDiff.type !== DifferenceType.UNCHANGED) {
          diff.type = DifferenceType.MODIFIED;
        }
      }
      break;
    case ValueType.NULL:
      // It's unchanged and we've already detected a type change, don't touch it
      break;
    case ValueType.BOOLEAN:
    case ValueType.DOUBLE:
    case ValueType.INT:
    case ValueType.STRING:
    default:
      // Same type means both must exist
      if (sameType && a!.value !== b!.value) {
        // Modified
        diff.type = DifferenceType.MODIFIED;
      }
      break;
  }

  return diff;
}

export function newGetDiff(a?: any, b?: any) {
  const aSettings = a?.entities.settings;
  const bSettings = b?.entities.settings;

  // Get parent settings
  const aStartKey = a?.result;
  const bStartKey = b?.result;

  const aSetting = aSettings[aStartKey];
  const bSetting = bSettings[bStartKey];

  const diff: DifferenceEntry = {
    type: DifferenceType.UNCHANGED,
    children: {},
  };

  // Recursively compare settings
  for (let i = 0; i < aSetting.value.length; i++) {
    const aValue = aSettings[aSetting.value[i]];
    const bValue = bSettings[bSetting.value[i]];

    const childDiff = recursiveLoop(aSettings, aValue, bSettings, bValue);
    diff.children[aValue.name] = childDiff;
  }

  return diff;
}

export function recursiveLoop(
  aSettings?: any,
  aValue?: any,
  bSettings?: any,
  bValue?: any,
) {
  const diff: DifferenceEntry = {
    type: DifferenceType.UNCHANGED,
    children: {},
  };

  // Evaluate differences
  if (!aValue) {
    // a doesn't have it
    if (!bValue) {
      // b doesn't have it either, it's unchanged technically
      diff.type = DifferenceType.UNCHANGED;

      return diff;
    }
    // Must have been added
    diff.type = DifferenceType.ADDITION;
  } else if (!bValue) {
    // b doesnt have it, must have been removed
    diff.type = DifferenceType.DELETION;
  }
  // If not same type, must be modified
  const sameType = aValue?.type === bValue?.type;
  if (aValue && bValue && !sameType) {
    diff.type = DifferenceType.MODIFIED;
  }

  if (aValue?.type === 'array' || aValue?.type === 'object') {
    const maxLength = Math.max(aValue?.value.length, bValue?.value.length);

    const valueAClone = { ...aValue };

    /*    // Sort values
    if (aValue?.type !== 'array') {
      valueAClone.value.slice().sort((a: any, b: any) => {
        const aSettingName = aSettings[a].name;
        const bSettingName = aSettings[b].name;

        return aSettingName.localeCompare(bSettingName);
      });
    }*/

    const valueBClone = { ...bValue };

    /*    if (bValue?.type !== 'array') {
      valueBClone.value.slice().sort((a: any, b: any) => {
        const aSettingName = bSettings[a].name;
        const bSettingName = bSettings[b].name;

        return aSettingName.localeCompare(bSettingName);
      });
    }*/

    // Recursively compare settings
    for (let i = 0; i < maxLength; i++) {
      const newAValue = aSettings[valueAClone.value[i]];
      const newBValue = bSettings[valueBClone.value[i]];
      const childDiff = recursiveLoop(
        aSettings,
        newAValue,
        bSettings,
        newBValue,
      );

      diff.children[newBValue ? newBValue?.name : newAValue?.name] = childDiff;
      if (sameType && childDiff.type !== DifferenceType.UNCHANGED) {
        diff.type = DifferenceType.MODIFIED;
      }
    }
  } else {
    if (sameType && aValue.value !== bValue.value) {
      diff.type = DifferenceType.MODIFIED;
    }
  }

  return diff;
}
