import React from 'react';
import { Input } from 'reactstrap';
import { FieldContext } from '../Field/Field';

export interface Props {
  type: string;
  value: string | number;
  disabled?: boolean;
  plaintext?: boolean;
  handleChange: (key: string, value: any) => void;
  id?: string;
}

const InputField = (props: Props) => {
  const context = React.useContext(FieldContext)!;

  return (
    <Input
      plaintext={props.plaintext}
      disabled={props.disabled ?? context.disabled}
      type={props.type as any}
      step={1}
      className="field-content"
      value={props.value ?? ''}
      onChange={event => {
        props.handleChange(props.id ?? context.id, event.target.value);
      }}
      placeholder={`Enter ${context.title}...`}
    />
  );
};

export default InputField;
