import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { Card, CardBody } from 'reactstrap';
import { CohortedTest } from '../../model/cohorted-test.model';
import { PopoverState } from '../CohortedTestForm/CohortedTestForm';
import { FieldHeader } from './FieldHeader/FieldHeader';

export const FieldContext = React.createContext<
  | {
      id: keyof CohortedTest;
      disabled: boolean;
      title: string;
      required?: boolean;
    }
  | undefined
>(undefined);

export interface Props {
  id: keyof CohortedTest;
  title: string;
  hoverText?: string;
  setPopover?: (state?: PopoverState) => void;
  children?: ReactNode;
  disabled?: boolean;
  required?: boolean;
}

const Field = (props: Props) => {
  return (
    <Card className={classNames({ disabled: props.disabled })}>
      <FieldContext.Provider
        value={{
          id: props.id,
          disabled: !!props.disabled,
          title: props.title,
          required: props.required,
        }}
      >
        <FieldHeader
          id={props.id}
          title={props.title}
          hoverText={props.hoverText}
          setPopover={props.setPopover}
          disabled={props.disabled}
        />
        <CardBody>{props.children}</CardBody>
      </FieldContext.Provider>
    </Card>
  );
};
export default Field;
