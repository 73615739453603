import { createSlice } from '@reduxjs/toolkit';
import { ToastTypes } from '../../enums/ToastTypes';
import { CohortedTest } from '../../model/cohorted-test.model';
import { fetchGameEditions, fetchGameSettings } from './operations';

export type CohortedTestsState = {
  cohortedTests: CohortedTest[];
  fetchingPending?: boolean;
  loadingGameSettings?: boolean;
  gameSettings?: any[];
  error?: any;
  selectedTestId?: string;
  creationPending?: boolean;
  deletionPending?: boolean;
  updatePending?: boolean;
  selectedTest?: CohortedTest;
  actionPending?: boolean;
  addFeaturePending?: boolean;
  newVariantCodes: { [id: string]: { feature: string; variant: string }[] };
  currentToast?: { type: ToastTypes; message: string };
  config?: any;
  gameEditionsMap?: any;
  loadingGameEditions?: boolean;
};

const initialState: CohortedTestsState = {
  cohortedTests: [],
  newVariantCodes: {},
};

const cohortedTestsPageSlice = createSlice({
  name: 'cohortedTestsPage',
  initialState,
  reducers: {
    fetchCohortedTestsPending(state) {
      state.fetchingPending = true;
    },
    fetchCohortedTestsSuccess(state, action) {
      state.fetchingPending = false;
      state.cohortedTests = action.payload;
    },
    fetchCohortedTestsError(state, error) {
      state.fetchingPending = false;
      state.error = error;
    },
    setSelectedTest(state, action) {
      state.selectedTestId = action.payload;
    },
    addNewTestPending(state) {
      state.creationPending = true;
    },
    addNewTestSuccess(state, action) {
      state.creationPending = false;
      state.cohortedTests.push(action.payload);
      state.selectedTestId = action.payload.object_id;
    },
    addNewTestError(state, error) {
      state.creationPending = false;
      state.error = error;
    },
    deleteTestPending(state) {
      state.deletionPending = true;
    },
    deleteTestSuccess(state, action) {
      state.deletionPending = false;
      const index = state.cohortedTests.findIndex(
        test => test.object_id === action.payload.object_id,
      );
      state.cohortedTests.splice(index, 1);
    },
    deleteTestError(state, error) {
      state.deletionPending = false;
      state.error = error;
    },
    changeTestContents(state, action) {
      const index = state.cohortedTests.findIndex(
        test => test.object_id === action.payload.object_id,
      );
      state.cohortedTests[index] = action.payload;
    },
    changeFeatureContents(state, action) {
      const selectedTest = getSelectedTest(state);
      const index = selectedTest!.features!.findIndex(
        feature => feature.code === action.payload.code,
      );

      selectedTest!.features![index] = action.payload;
    },
    changeVariantContents(state, action) {
      const { variant, featureId } = action.payload;
      const selectedTest = getSelectedTest(state);
      const index = selectedTest!.features!.findIndex(
        feature => feature.code === featureId,
      );
      const variantIndex = selectedTest!.features![index].variants.findIndex(
        (currentVariant: any) => currentVariant.code === variant.code,
      );

      selectedTest!.features![index].variants[variantIndex] = variant;
    },
    updateTestPending(state) {
      state.updatePending = true;
    },
    updateTestSuccess(state, action) {
      state.updatePending = false;
      const index = state.cohortedTests.findIndex(
        test => test.object_id === action.payload.object_id,
      );
      state.cohortedTests[index] = action.payload;
      state.newVariantCodes = {};
      state.currentToast = {
        type: ToastTypes.SUCCESS,
        message: 'Test saved successfully',
      };
    },
    updateTestError(state, error) {
      state.updatePending = false;
      state.error = error;
      state.currentToast = {
        type: ToastTypes.ERROR,
        message: 'Failed to save test: ' + error.payload.message,
      };
    },
    executeActionPending(state) {
      state.actionPending = true;
    },
    executeActionSuccess(state, action) {
      const successMessage =
        action.payload.successMessage ?? 'Action executed successfully';
      state.actionPending = false;

      const index = state.cohortedTests.findIndex(
        test => test.object_id === action.payload.test.object_id,
      );
      state.cohortedTests[index] = action.payload.test;
      state.currentToast = {
        type: ToastTypes.SUCCESS,
        message: successMessage,
      };
    },
    executeActionError(state, action) {
      console.log(action);
      const errorMessage =
        action.payload.errorMessage ?? 'Failed to execute action';
      const error = action.payload.error;

      state.actionPending = false;
      state.error = error;
      state.currentToast = {
        type: ToastTypes.ERROR,
        message: errorMessage + ': ' + error.message,
      };
    },
    addVariant(state, action) {
      const { newVariant, featureId } = action.payload;
      const selectedTest = getSelectedTest(state);
      const index = selectedTest!.features!.findIndex(
        feature => feature.code === featureId,
      );

      if (!state.newVariantCodes[state.selectedTestId!]) {
        state.newVariantCodes[state.selectedTestId!] = [];
      }

      state.newVariantCodes[state.selectedTestId!].push({
        feature: featureId,
        variant: newVariant.code,
      });

      selectedTest!.features![index].variants.push(newVariant);
    },
    deleteVariant(state, action) {
      const { variantId, featureId } = action.payload;
      const selectedTest = getSelectedTest(state);
      const index = selectedTest!.features!.findIndex(
        feature => feature.code === featureId,
      );

      const variantIndex = selectedTest!.features![index].variants.findIndex(
        (variant: any) => variant.code === variantId,
      );

      selectedTest!.features![index].variants.splice(variantIndex, 1);
    },
    showToast(state, action) {
      state.currentToast = action.payload;
    },
    clearError(state) {
      state.error = undefined;
    },
    setConfig(state, action) {
      state.config = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchGameEditions.pending, state => {
      state.loadingGameEditions = true;
    });
    builder.addCase(fetchGameEditions.fulfilled, (state, { payload }) => {
      const gameEditionsMap: { [id: string]: string } = {};

      payload?.forEach(
        (edition: any) =>
          (gameEditionsMap[edition.name] = edition.display_name),
      );

      state.gameEditionsMap = gameEditionsMap;
      state.loadingGameEditions = false;
    });
    builder.addCase(fetchGameEditions.rejected, state => {
      state.loadingGameEditions = false;
    });
    builder.addCase(fetchGameSettings.pending, state => {
      state.loadingGameSettings = true;
    });
    builder.addCase(fetchGameSettings.fulfilled, (state, action) => {
      state.gameSettings = action.payload;
      state.loadingGameSettings = false;
    });
    builder.addCase(fetchGameSettings.rejected, state => {
      state.loadingGameSettings = false;
    });
  },
});

export const {
  fetchCohortedTestsPending,
  fetchCohortedTestsSuccess,
  fetchCohortedTestsError,
  setSelectedTest,
  addNewTestPending,
  addNewTestSuccess,
  addNewTestError,
  deleteTestPending,
  deleteTestSuccess,
  deleteTestError,
  updateTestPending,
  updateTestSuccess,
  updateTestError,
  executeActionPending,
  executeActionSuccess,
  executeActionError,
  changeTestContents,
  addVariant,
  deleteVariant,
  changeFeatureContents,
  changeVariantContents,
  showToast,
  clearError,
  setConfig,
} = cohortedTestsPageSlice.actions;

export const getCohortedTests = (state: any) => state.cohortedTests;
export const getCohortedTestsPending = (state: any) => state.fetchingPending;
export const getCohortedTestsError = (state: any) => state.error;

export const getSelectedTestId = (state: any) => state.selectedTestId;
export const getSelectedTest = (state: CohortedTestsState) =>
  state.cohortedTests.find(test => test.object_id === state.selectedTestId);

export const getAddNewTestPending = (state: any) => state.creationPending;

export const getUpdateCohortedTestPending = (state: any) => state.updatePending;

export const getAddFeaturePending = (state: any) => state.addFeaturePending;

export const getNewVariantCodes = (state: any) => state.newVariantCodes;

export const currentToastSelector = (state: any) =>
  state.cohortedTestsPageReducer.currentToast;

export const configSelector = (state: any) =>
  state.cohortedTestsPageReducer.config;

export const getGameEditionsMap = (state: CohortedTestsState) =>
  state.gameEditionsMap;
export const getLoadingGameEditions = (state: CohortedTestsState) =>
  state.loadingGameEditions;

export const getGameSettings = (state: any) => state.gameSettings;
export const getLoadingGameSettings = (state: any) => state.loadingGameSettings;

export default cohortedTestsPageSlice.reducer;
