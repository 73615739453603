import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError } from 'axios';
import React, { Component, Fragment } from 'react';
import { toast } from 'react-toastify';

interface Props {
  icon?: IconProp;
}

class Toast extends Component<Props, {}> {
  render() {
    return (
      <Fragment>
        {this.props.icon && (
          <div key="icon" className="icon">
            <FontAwesomeIcon icon={this.props.icon} />
          </div>
        )}
        <div key="content" className="content">
          {this.props.children}
        </div>
      </Fragment>
    );
  }
}

export function handleRequestError(err: Error) {
  let toastComponent = (
    <Toast icon={faExclamationTriangle}>
      Failed to process request, please try again in a little while...
    </Toast>
  );

  if (err.hasOwnProperty('response')) {
    const axiosError = err as AxiosError;
    if (axiosError.response && axiosError.response.status === 500) {
      toastComponent = (
        <Toast icon={faExclamationTriangle}>
          Internal server error, please contact a member of the server team...
        </Toast>
      );
    }
  }

  toast.error(toastComponent, { toastId: 'request_error' });

  console.error(err);
}

export default Toast;
