import {
  FontAwesomeIcon as FAI,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, MouseEvent } from 'react';

// @ts-ignore
interface Props extends FontAwesomeIconProps {
  id?: string;
  onClick?: (event: MouseEvent<HTMLSpanElement>) => void;
  'aria-hidden'?: boolean;
}

export const FontAwesomeIcon: FunctionComponent<Props> = props => {
  const { id, onClick, 'aria-hidden': ariaHidden, ...remaining } = props;

  return (
    <span
      className="font-awesome-icon"
      id={id}
      onClick={onClick}
      aria-hidden={ariaHidden}
    >
      <FAI {...remaining} />
    </span>
  );
};
