import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import React, { Component, CSSProperties } from 'react';
import { CSSTransition } from 'react-transition-group';

interface Props
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  src: string;

  width: number;
  height: number;

  containerClassName?: string;
  containerStyle?: CSSProperties;

  parent?: Element;

  borderRadius?: number;
  fill?: string;

  disableViewportCheck?: boolean;
}

interface State {
  isInViewport: boolean;
  isLoaded: boolean;
  hasError: boolean;
}

export default class LazyLoadedImage extends Component<Props, State> {
  state: State = {
    isInViewport: false,
    isLoaded: false,
    hasError: false,
  };

  private containerRef = React.createRef<HTMLDivElement>();

  private observer?: IntersectionObserver;

  componentDidMount() {
    this.setupIntersectionObserver();
  }

  shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<State>,
    nextContext: any,
  ): boolean {
    if (!isEqual(this.props, nextProps)) {
      return true;
    }

    if (this.state.isLoaded !== nextState.isLoaded) {
      return true;
    }

    return (
      !this.props.disableViewportCheck &&
      this.state.isInViewport !== nextState.isInViewport
    );
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any,
  ) {
    if (prevProps.src !== this.props.src) {
      this.setState({ isLoaded: false, hasError: false });
    }

    if (
      prevProps.parent !== this.props.parent ||
      prevProps.disableViewportCheck !== this.props.disableViewportCheck
    ) {
      this.setupIntersectionObserver();
    }
  }

  setupIntersectionObserver() {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = undefined;
    }

    if (this.props.disableViewportCheck) {
      return;
    }

    let container = this.props.parent;
    if (!container) {
      container = document.body;
    }

    this.observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          this.setState({ isInViewport: entry.isIntersecting });
        });
      },
      {
        root: container,
        rootMargin: `${container.clientHeight * 2}px ${container.clientWidth *
          2}px`,
      },
    );

    if (this.containerRef.current) {
      this.observer.observe(this.containerRef.current);
    }
  }

  render() {
    let {
      borderRadius,
      // eslint-disable-next-line prefer-const
      containerClassName,
      // eslint-disable-next-line prefer-const
      containerStyle,
      fill,
      // eslint-disable-next-line prefer-const
      parent,
      // eslint-disable-next-line prefer-const
      disableViewportCheck,
      ...props
    } = this.props;

    props = { ...props };

    // Don't show image if not in viewport
    const inViewport = disableViewportCheck || this.state.isInViewport;
    if (!inViewport && !this.state.isLoaded) {
      delete props.src;
    }

    if (this.state.hasError || (inViewport && !props.src)) {
      props.src = `${process.env.PUBLIC_URL}/images/missing-image.png`;
    }

    // onLoad
    const onLoad = props.onLoad;
    props.onLoad = event => {
      this.setState({ isLoaded: true });

      if (onLoad) {
        onLoad(event);
      }
    };

    props.onError = () => {
      this.setState({ hasError: true });
    };

    // Styling
    let style: CSSProperties;
    if (this.state.isLoaded) {
      style = {
        opacity: 1,
      };
    } else {
      style = {
        visibility: 'hidden',
        opacity: 0,
      };
    }
    props.style = {
      ...props.style,
      ...style,
    };

    // Placeholder defaults
    borderRadius = borderRadius ?? 10;
    fill = fill ?? '#ced4da';

    return (
      <div
        className={classNames('lazy-loaded-image', containerClassName)}
        style={{
          width: `${props.width}px`,
          height: `${props.height}px`,
          ...containerStyle,
        }}
        ref={this.containerRef}
      >
        <CSSTransition
          in={!this.state.isLoaded}
          timeout={200}
          unmountOnExit={true}
          appear={false}
        >
          <svg
            width={this.props.width}
            height={this.props.height}
            viewBox={`0 0 ${this.props.width} ${this.props.height}`}
          >
            <rect
              width={this.props.width}
              height={this.props.height}
              rx={borderRadius}
              ry={borderRadius}
              fill={fill}
            />
          </svg>
        </CSSTransition>

        <img {...props} alt={props.alt ?? ''} />
      </div>
    );
  }
}
