import { merge } from 'lodash-es';
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { Action, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { GlobalState } from '../../model/cohorted-test.model';
import { CohortedTestsState } from '../../pages/CohortedTestsPage/cohortedTestsPageSlice';
import {
  getVariantsSuccess,
  setVariant,
} from '../CohortedTestForm/Feature/featureSlice';
import {
  addIdsToSettings,
  denormalizeSettings,
  normaliseSettings,
} from '../CohortedTestForm/Feature/operations';

type Props = {
  readOnly?: boolean;
  variants?: any;
  setVariant: (state: { id: string; data: any }) => void;
};

function BatchVariantImport(props: Props) {
  const [isOpen, _setOpen] = useState(false);
  const [isImporting, setImporting] = useState(false);

  const [json, setJson] = useState('');

  const setOpen = (open: boolean) => {
    if (isImporting) {
      return;
    }

    _setOpen(open);
  };

  const onImport = useCallback(async () => {
    let parsed: Record<string, any> | undefined = undefined;
    try {
      parsed = JSON.parse(json);
      if (!parsed || typeof parsed !== 'object' || Array.isArray(parsed)) {
        parsed = undefined;
      }
    } catch (error) {
      console.error(error);
    }

    if (!parsed) {
      alert('Invalid JSON'); // TODO Better warning

      return;
    }

    setImporting(true);

    // Give time for interface to disable
    await new Promise(resolve => setTimeout(resolve, 10));

    for (const key of Object.keys(parsed)) {
      if (key === 'control') {
        continue;
      }

      const variant = props.variants[key];
      if (!variant) {
        continue;
      }

      const settings = denormalizeSettings(
        variant.entities.settings,
        [variant.result],
        false,
      );

      const combined = merge(settings, { settings: parsed[key] });

      const variantWithIds = addIdsToSettings(combined);
      const normalised = normaliseSettings(variantWithIds);

      props.setVariant({ id: key, data: normalised });
    }

    setImporting(false);
    setOpen(false);
  }, [props.variants, json, props.setVariant, setOpen, setImporting]);

  return (
    <>
      <Button
        disabled={!!props.readOnly}
        color="primary"
        className="ml-2"
        onClick={() => setOpen(true)}
      >
        Batch Import
      </Button>

      <Modal isOpen={isOpen} toggle={() => setOpen(false)}>
        <ModalHeader>Batch Import</ModalHeader>
        <ModalBody>
          <Input
            disabled={isImporting}
            type="textarea"
            value={json}
            onChange={event => setJson(event.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={isImporting} onClick={onImport}>
            {isImporting ? 'Importing...' : 'Import'}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<CohortedTestsState, void, Action>,
) =>
  bindActionCreators(
    {
      setVariant: setVariant,
    },
    dispatch,
  );

const mapStateToProps = (state: GlobalState) => {
  const featureState = state.featureReducer;

  return {
    variants: getVariantsSuccess(featureState),
  };
};

const BatchVariantImportWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BatchVariantImport);

export default BatchVariantImportWrapper;
