import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { Action, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { CohortedTest } from '../../model/cohorted-test.model';
import {
  getGameEditionsMap,
  getLoadingGameEditions,
  setSelectedTest,
} from '../../pages/CohortedTestsPage/cohortedTestsPageSlice';
import { addNewTest } from '../../pages/CohortedTestsPage/operations';
import {
  CohortedTestsState,
  getAddNewTestPending,
  getCohortedTests,
  getCohortedTestsError,
  getCohortedTestsPending,
  getSelectedTestId,
} from '../../pages/CohortedTestsPage/cohortedTestsPageSlice';
import { getGameID } from '../../util/game-admin.util';
import Field from '../Field/Field';
import InputField from '../InputField/InputField';
import SelectField, {
  fallbackGameEditions,
  GameEdition,
  TargetType,
} from '../SelectField/SelectField';
import Toast from '../Misc/Toast';

interface Props {
  isOpen?: boolean;
  title?: ReactNode;
  confirmColor?: string;
  confirmContent?: ReactNode;
  denyColor?: string;
  denyContent?: ReactNode;
  onClose?: () => void;
  onResolve?: (resolved: boolean) => void;
  gameId?: string;
  addNewTest: (newTest: Partial<CohortedTest>) => void;
  creationPending: boolean;
  countries: { [id: string]: string };
  gameEditionsMap?: any;
  loadingGameEditions?: boolean;
}

interface State {
  name?: string;
  description?: string;
  target_type?: TargetType;
  game_id?: string;
  game_edition_id?: GameEdition;
  max_version?: string;
  acquisition_limit?: number;
  isAcquisitionLimit: boolean;
  target_day: number;
  country_codes: string[];
}

class AddNewCohortedTest extends Component<Props, State> {
  state: State = {
    isAcquisitionLimit: false,
    target_type: 'new',
    target_day: 0,
    acquisition_limit: 1,
    country_codes: [],
  };

  componentDidMount(): void {
    toast.configure();
  }

  handleCancel = () => {
    const { onResolve } = this.props;

    if (onResolve) {
      onResolve(false);
    }
  };

  handleConfirm = () => {
    const { onResolve } = this.props;
    if (
      !this.state.name ||
      !this.state.description ||
      this.state.country_codes.length === 0 ||
      !this.state.game_edition_id
    ) {
      toast.error(
        <Toast icon={faExclamationTriangle}>
          Please fill out all required fields
        </Toast>,
        {
          toastId: 'empty_fields',
        },
      );
    } else {
      // Add new test
      const game_id = getGameID(this.props.gameId);
      const newTest = {
        name: this.state.name,
        description: this.state.description,
        target_day: this.state.target_day,
        game_edition_id: this.state.game_edition_id,
        groups: [],
        country_codes: this.state.country_codes,
        category_id: 'default',
        game_id: game_id!,
        client_id: null,
      };

      this.props.addNewTest(newTest);

      if (onResolve) {
        onResolve(true);
      }
    }
  };

  handleClose = () => {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }
  };

  handleChange(stateName: string, value: any) {
    // @ts-ignore
    this.setState({ [stateName]: value });
  }

  render() {
    return (
      <Modal
        className="optimus-modal"
        isOpen={this.props.isOpen}
        toggle={this.handleClose}
      >
        <ModalHeader toggle={this.handleClose}>
          Add new Cohorted Test
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Field id="name" title="Name" required>
                <InputField
                  type="text"
                  value={this.state.name!}
                  handleChange={this.handleChange.bind(this)}
                />
              </Field>
            </FormGroup>
            <FormGroup>
              <Field id="description" title="Description" required>
                <InputField
                  type="textarea"
                  value={this.state.description!}
                  handleChange={this.handleChange.bind(this)}
                />
              </Field>
            </FormGroup>
            <Field id="game_edition_id" title="Game Edition" required>
              <SelectField
                value={this.state.game_edition_id!}
                map={this.props.gameEditionsMap ?? fallbackGameEditions}
                handleChange={this.handleChange.bind(this)}
              />
            </Field>
            <Field id="country_codes" title="Countries" required>
              <SelectField
                isMulti
                value={this.state.country_codes}
                map={this.props.countries}
                handleChange={this.handleChange.bind(this)}
                countries
              />
            </Field>

            <FormGroup>
              <Field id="target_day" title="Target Day">
                <InputField
                  type="number"
                  value={this.state.target_day}
                  handleChange={this.handleChange.bind(this)}
                />
              </Field>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color={this.props.confirmColor || 'primary'}
            onClick={this.handleConfirm}
            disabled={this.props.creationPending}
          >
            {this.props.creationPending
              ? 'Working'
              : this.props.confirmContent || 'Yes'}
          </Button>
          <Button
            className="ml-1"
            color={this.props.denyColor || 'secondary'}
            onClick={this.handleCancel}
          >
            {this.props.denyContent || 'No'}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state: {
  cohortedTestsPageReducer: CohortedTestsState;
}) => {
  const cohortedTestsState = state.cohortedTestsPageReducer;

  return {
    error: getCohortedTestsError(cohortedTestsState),
    cohortedTests: getCohortedTests(cohortedTestsState),
    pending: getCohortedTestsPending(cohortedTestsState),
    selectedTestId: getSelectedTestId(cohortedTestsState),
    creationPending: getAddNewTestPending(cohortedTestsState),
    gameEditionsMap: getGameEditionsMap(cohortedTestsState),
    loadingGameEditions: getLoadingGameEditions(cohortedTestsState),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<CohortedTestsState, void, Action>,
) =>
  bindActionCreators(
    {
      setSelectedTest: setSelectedTest,
      addNewTest: addNewTest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddNewCohortedTest);
