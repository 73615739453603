import React from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { configSelector } from '../../pages/CohortedTestsPage/cohortedTestsPageSlice';
import isNullOrUndefined from '../../util/isNullOrUndefined';
import keys from '../../util/keys';
import { toReactSelectObject } from '../../util/toReactSelectObject';
import AdvancedSelect from '../AdvancedSelect/AdvancedSelect';
import { FieldContext } from '../Field/Field';

export interface Props {
  value: string | number | any[];
  map?: { [id: string]: string };
  disabled?: boolean | string[];
  isMulti?: boolean;
  handleChange: (key: string, value: any) => void;
  id?: string;
  className?: string;
  countries?: boolean;
  fieldRenderer?: any;
  placeholder?: string;
  cohortedTestId?: string;
}

export type TargetType = keyof typeof TargetTypes;

export const TargetTypes = {
  new: 'New',
  all: 'All',
  segmented: 'Segmented',
};

export const DisabledTargetTypes: (keyof typeof TargetTypes)[] = [
  'all',
  'segmented',
];

export type GameEdition = keyof typeof fallbackGameEditions;

export const fallbackGameEditions = {
  android: 'Android',
  ios: 'iOS',
  imessage: 'iMessage',
  facebook_canvas: 'Facebook Canvas',
  amazon: 'Amazon',
};

const SelectField = (props: Props) => {
  const config = useSelector(configSelector);
  const context = React.useContext(FieldContext)!;

  const map = props.map ?? {};

  return props.countries ? (
    <AdvancedSelect
      // Replace with loading state
      disabled={
        !props.disabled
          ? context && context.disabled
          : typeof props.disabled === 'boolean' && props.disabled
      }
      small
      nullable
      multiselect
      options={keys(map).map(id =>
        toReactSelectObject(
          map,
          id,
          Array.isArray(props.disabled) ? props.disabled : undefined,
        ),
      )}
      getOptionKey={type => type.value}
      getOptionLabel={type => type.label}
      selected={
        Array.isArray(props.value)
          ? props.value.map((value: string) => toReactSelectObject(map, value))
          : toReactSelectObject(map, props.value)
      }
      onOptionSelected={(option, selected, newSelected) => {
        let selectedOption: any[] = [];
        if (newSelected) {
          selectedOption = Array.isArray(newSelected)
            ? newSelected
            : [newSelected];
        }

        props.handleChange(
          props.id ?? context.id,
          (selectedOption ?? []).map(
            (selectedOption: any) => selectedOption.value,
          ),
        );
      }}
      selectionGroups={config?.settings.country_groups.groups}
      selectionGroupsOrder={config?.settings.country_groups.order}
      cohortedTestId={props.cohortedTestId}
    />
  ) : (
    <Select
      className={`field-content ${props.className}`}
      isMulti={props.isMulti}
      isDisabled={
        isNullOrUndefined(props.disabled)
          ? context.disabled
          : typeof props.disabled === 'boolean' && props.disabled
      }
      value={
        Array.isArray(props.value)
          ? props.value.map((value: string) => toReactSelectObject(map, value))
          : toReactSelectObject(map, props.value)
      }
      options={keys(map).map(id =>
        toReactSelectObject(
          map,
          id,
          Array.isArray(props.disabled) ? props.disabled : undefined,
        ),
      )}
      placeholder={props.placeholder ?? 'Select...'}
      onChange={options =>
        props.handleChange(
          props.id ?? context.id,
          props.isMulti
            ? (options ?? []).map((option: any) => option.value)
            : options.value,
        )
      }
    />
  );
};

export default SelectField;
